define("api-dashboard/components/return-to-suppliers/return-to-supplier-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'supplier-return',
    title: 'Devolução a Fornecedor',
    titlePlural: 'Devoluções a Fornecedores',
    newRoute: 'return-to-suppliers/new',
    editRoute: 'return-to-suppliers/edit',
    partialStats: null,
    customSearchButton: true,
    showFilters: true,
    showRefinedSearch: true,
    allowDelete: false,
    onlyShowItem: true,
    columns: [{
      field: 'entryDate',
      label: 'Data de Movimentação',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY'
      }
    }, {
      field: 'comment',
      label: 'Observações'
    }],
    searchParams: [{
      label: 'Data De',
      field: 'entryDate',
      param: 'date_from',
      component: 'inputs/input-date',
      format: 'date',
      options: {
        format: 'YYYY-MM-DD'
      },
      val: null
    }, {
      label: 'Data Até',
      field: 'entryDate',
      param: 'date_to',
      component: 'inputs/input-date',
      format: 'date',
      options: {
        format: 'YYYY-MM-DD'
      },
      val: null
    }],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/waybills/waybill-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isShowingAdvancedOptions: false,
    application: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    constants: Ember.inject.service(),
    actions: {
      createWaybill: function createWaybill() {
        return this.createWaybill();
      },
      cancel: function cancel() {
        return this.cancel();
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/manual-input-output/manual-input-output-form", ["exports", "api-dashboard/mixins/formatters", "api-dashboard/mixins/keyboard-utils"], function (_exports, _formatters, _keyboardUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, _keyboardUtils.default, {
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    router: Ember.inject.service(),
    _productListColumns: ['description', 'quantity', 'price', 'totalPrice'],
    manualTypeObserver: Ember.observer('manualType', function () {
      var newModel, type;
      type = this.get('manualType');
      newModel = null;

      if (type === 'ENTRY') {
        newModel = this.get('store').createRecord('manual-entry');
      } else {
        newModel = this.get('store').createRecord('manual-exit');
      }

      return this.copyData(newModel);
    }),
    storeObserver: Ember.observer('model.store', function () {
      var store, type;
      store = this.get('model.store');
      type = this.get('manualType');

      if (type === 'ENTRY') {
        this.set('model.originStore', null);
        return this.set('model.destinationStore', store);
      } else {
        this.set('model.originStore', store);
        return this.set('model.destinationStore', null);
      }
    }),
    copyData: function copyData(newModel) {
      newModel.set('store', this.get('model.store'));
      newModel.set('comment', this.get('model.comment'));
      newModel.set('entryDate', this.get('model.entryDate'));
      newModel.set('originStore', this.get('model.originStore'));
      newModel.set('destinationStore', this.get('model.destinationStore'));

      if (this.get('model.items')) {
        newModel.set('items', this.get('model.items'));
      }

      this.get('store').unloadRecord(this.get('model'));
      return this.set('model', newModel);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        var model;
        model = this.get('store').createRecord('trade');
        model.set('entryDate', new Date());
        this.set('model', model);
        this.set('manualType', null);
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel() {
        this.get('model').rollbackAttributes();

        if (!this.get('model.id')) {
          this.get('store').unloadRecord(this.get('model'));
        }

        return this.sendAction('onCancel', null);
      }
    }
  });

  _exports.default = _default;
});
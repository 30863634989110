define("api-dashboard/components/kits/kit-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'kit',
    title: 'Kit',
    titlePlural: 'Kits',
    newRoute: 'kits/new',
    editRoute: 'kits/edit',
    partialStats: null,
    columns: [{
      field: 'code',
      label: 'Código'
    }, {
      field: 'barcode',
      label: 'Código de Barras'
    }, {
      field: 'name',
      label: 'Nome'
    }, {
      field: 'activeDescription',
      label: 'Ativo'
    }],
    searchParams: [{
      field: 'code',
      param: 'code',
      label: 'Código'
    }, {
      field: 'name',
      param: 'name',
      label: 'Nome'
    }, {
      component: 'inputs/input-toggle',
      field: 'active',
      label: 'Incluir Inativos',
      param: 'show_inactive',
      value: false
    }],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/departments/department-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onSubmit: function onSubmit(department) {
        return this.sendAction('afterSave', department);
      },
      onCancel: function onCancel(department) {
        if (department == null) {
          department = null;
        }

        return this.sendAction('onCancel', department);
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/manual-entries/manual-entry-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    partialActions: 'components/manual-entries/manual-entry-form-actions',
    loadingProductInitialQuantity: false,
    selectedItemsTotalCount: 0,
    totalStockQuantity: 0,
    selectedProductNewQuantityObserver: Ember.observer('selectedProductNewQuantity', 'selectedIventoryAdjustmentType', function () {
      var initialQuantity, newQuantity, totalStockQuantity;
      initialQuantity = parseInt(this.get('selectedProductInitialQuantity'));
      newQuantity = parseInt(this.get('selectedProductNewQuantity'));
      totalStockQuantity = 0;

      if (this.get('selectedIventoryAdjustmentType') === 0) {
        totalStockQuantity = initialQuantity + newQuantity;
      }

      if (this.get('selectedIventoryAdjustmentType') === 1) {
        totalStockQuantity = initialQuantity - newQuantity;
      }

      if (isNaN(totalStockQuantity)) {
        totalStockQuantity = '-';
      }

      return this.set('totalStockQuantity', totalStockQuantity);
    }),
    init: function init() {
      if (!this.get('application.currentDomain.multipleStores')) {
        this.set('selectedStore', this.get('application.currentDomain.visibleStores').objectAt(0));
      }

      return this._super.apply(this, arguments);
    },
    clearViewFields: function clearViewFields() {
      this.set('selectedProduct', null);
      this.set('selectedProductInitialQuantity', 0);
      this.set('selectedProductNewQuantity', 0);
      this.set('comment', null);
      this.set('selectedIventoryAdjustmentType', null);
      return this.set('totalStockQuantity', null);
    },
    actions: {
      save: function save(record) {
        var ajaxParams, data, entradaOuSaidaString, entry, self, url;
        self = this;
        entradaOuSaidaString = '';

        if (self.get('selectedIventoryAdjustmentType') === null || self.get('selectedIventoryAdjustmentType') === void 0) {
          self.get('application').showAlert('A operação a ser realizada deve ser escolhida (entrada ou saída)!', 'error', 8000);
          return;
        }

        entry = {};
        entry.total_cost = '';
        entry.total_value = '';
        entry.item_count = self.get('selectedProductNewQuantity');
        entry.entry_date = '';
        entry.comment = self.get('comment');
        entry.items_attributes = [{
          product_id: self.get('selectedProduct.id'),
          quantity: self.get('selectedProductNewQuantity'),
          domain_id: self.get('application.currentDomain.id')
        }];

        if (self.get('selectedIventoryAdjustmentType') === 0) {
          entry.destination_store_id = self.get('selectedStore.id');
          entry.origin_store_id = '';
          data = {
            manual_entry: entry
          };
          url = 'manual_entries';
          entradaOuSaidaString = 'Entrada';
        }

        if (self.get('selectedIventoryAdjustmentType') === 1) {
          entry.destination_store_id = '';
          entry.origin_store_id = self.get('selectedStore.id');
          data = {
            manual_exit: entry
          };
          url = 'manual_exits';
          entradaOuSaidaString = 'Saída';
        }

        ajaxParams = {
          method: 'POST',
          data: JSON.stringify(data),
          contentType: "application/json",
          processData: false
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: "/" + url + ".json"
        }, function (success, data, jqXHR) {
          if (success) {
            self.get('application').showAlert(entradaOuSaidaString + " realizada com sucesso!", 'success');
            return self.clearViewFields();
          } else {
            return self.get('application').showAlert("Erro ao realizar a " + entradaOuSaidaString + "! " + 'Por favor contate o suporte@acras.com.br', 'error', 8000);
          }
        });
      },
      onSelectedProductChange: function onSelectedProductChange(record, refIndex) {
        var self;
        self = this;
        self.set('loadingProductInitialQuantity', true);
        return self.get('store').queryRecord('inventory', {
          store_id: self.get('selectedStore.id'),
          product_id: record.record.get('id')
        }).then(function (obj) {
          self.set('selectedProductInitialQuantity', obj.get('inStockQuantity'));
          return self.set('loadingProductInitialQuantity', false);
        });
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/services/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    saasPriceCnpjPriceMethodOptions: [{
      value: 'fixed',
      description: 'Valor fixo'
    }, {
      value: 'band',
      description: 'Por faixa'
    }],
    saasPriceNfeBandMethodOptions: [{
      value: 'all_units',
      description: 'Valor da faixa aplica a todas as unidades'
    }, {
      value: 'band_only',
      description: 'Valor da faixa aplica somente à faixa'
    }],
    person: {
      LEGAL_TYPE_LEGAL: 'LEGAL',
      LEGAL_TYPE_NATURAL: 'NATURAL'
    },
    legalTypeOptions: [{
      value: 'NATURAL',
      description: 'Pessoa Física'
    }, {
      value: 'LEGAL',
      description: 'Pessoa Jurídica'
    }],
    manualTypeOptions: [{
      value: 'ENTRY',
      description: 'Entrada'
    }, {
      value: 'EXIT',
      description: 'Saída'
    }],
    nfeStatus: [{
      value: 'autorizada',
      description: 'Autorizada'
    }, {
      value: 'cancelada',
      description: 'Cancelada'
    }, {
      value: 'em_processamento',
      description: 'Em processamento'
    }, {
      value: 'erro',
      description: 'Erro'
    }, {
      value: 'denegada',
      description: 'Denegada '
    }, {
      value: 'pendente',
      description: 'Pendente'
    }],
    nfseStatus: [{
      value: 'pendente',
      description: 'Pendente'
    }, {
      value: 'agendada',
      description: 'Agendada'
    }, {
      value: 'enviando',
      description: 'Enviando'
    }, {
      value: 'em_processamento',
      description: 'Em processamento'
    }, {
      value: 'autorizada',
      description: 'Autorizada'
    }, {
      value: 'enviando_cancelamento',
      description: 'Enviando Cancelamento'
    }, {
      value: 'processando_cancelamento',
      description: 'Processando Cancelamento'
    }, {
      value: 'cancelada',
      description: 'Cancelada'
    }, {
      value: 'erro_autorizacao',
      description: 'Erro de Autorização'
    }, {
      value: 'erro_cancelamento',
      description: 'Erro de cancelamento'
    }],
    contactInfo: Ember.computed(function () {
      return {
        types: ['phone', 'cellphone', 'email', 'fax', 'person', 'other'],
        typesWithLabel: [{
          value: 'phone',
          description: 'Telefone'
        }, {
          value: 'email',
          description: 'Email'
        }, {
          value: 'cellphone',
          description: 'Celular'
        }, {
          value: 'fax',
          description: 'Fax'
        }, {
          value: 'person',
          description: 'Pessoa'
        }, {
          value: 'other',
          description: 'Outros'
        }]
      };
    }),
    cfop: {
      LOCAL_DESTINO_INTERNA: 1,
      LOCAL_DESTINO_INTERESTADUAL: 2,
      LOCAL_DESTINO_EXTERIOR: 3
    },
    icmsOrigemValues: [{
      value: 0,
      description: "0 - Nacional"
    }, {
      value: 1,
      description: "1 - Estrangeira - Importação direta"
    }, {
      value: 2,
      description: "2 - Estrangeira - Adquirida no mercado interno"
    }, {
      value: 3,
      description: "3 - Nacional com mais de 40% de conteúdo estrangeiro"
    }, {
      value: 4,
      description: "4 - Nacional - Processos Produtivos Básicos"
    }, {
      value: 5,
      description: "5 - Nacional com menos de 40% de conteúdo estrangeiro"
    }, {
      value: 6,
      description: "6 - Estrangeira por importação direta que não tem similar nacional"
    }, {
      value: 7,
      description: "7 - Estrangeira adquirida no mercado interno e que não tem similar nacional"
    }],
    icmsSituacaoTributariaNormalPossibleValues: [{
      description: "00 - Tributada integralmente",
      value: "00"
    }, {
      description: "10 - Tributada e com cobrança do ICMS por substituição tributária",
      value: "10"
    }, {
      description: "20 - Com redução de base de cálculo",
      value: "20"
    }, {
      description: "30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária",
      value: "30"
    }, {
      description: "40 - Isenta",
      value: "40"
    }, {
      description: "41 - Não tributada",
      value: "41"
    }, {
      description: "50 - Suspensão",
      value: "50"
    }, {
      description: "51 - Diferimento",
      value: "51"
    }, {
      description: "60 - ICMS cobrado anteriormente por substituição tributária",
      value: "60"
    }, {
      description: "70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária",
      value: "70"
    }, {
      description: "90 - Outras",
      value: "90"
    }],
    icmsSituacaoTributariaSimplesPossibleValues: [{
      description: "101 - Tributada pelo Simples Nacional com permissão de crédito",
      value: "101"
    }, {
      description: "102 - Tributada pelo Simples Nacional sem permissão de crédito",
      value: "102"
    }, {
      description: "103 – Isenção do ICMS no Simples Nacional para faixa de receita bruta",
      value: "103"
    }, {
      description: "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária",
      value: "201"
    }, {
      description: "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária",
      value: "202"
    }, {
      description: "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária",
      value: "203"
    }, {
      description: "300 – Imune",
      value: "300"
    }, {
      description: "400 – Não tributada pelo Simples Nacional",
      value: "400"
    }, {
      description: "500 – ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
      value: "500"
    }, {
      description: "900 - Outros",
      value: "900"
    }],
    icmsModalidadeBaseCalculoValues: [{
      value: 0,
      description: "0 - Margem Valor Agregado (%)"
    }, {
      value: 1,
      description: "1 - Pauta (valor)"
    }, {
      value: 2,
      description: "2 - Preço Tabelado Máximo (valor)"
    }, {
      value: 3,
      description: "3 - Valor da Operação"
    }],
    icmsMotivoDesoneracaoValues: [{
      value: 1,
      description: "1 – Táxi"
    }, {
      value: 3,
      description: "3 – Produtor agropecuário"
    }, {
      value: 4,
      description: "4 – Frotista/locadora"
    }, {
      value: 5,
      description: "5 – Diplomático/consular"
    }, {
      value: 6,
      description: "6 – Utilitários e motocicletas da Amazônia Ocidental e áreas de livre comércio (resolução 714/88 e 790/94 – CONTRAN e suas alterações)"
    }, {
      value: 7,
      description: "7 – SUFRAMA"
    }, {
      value: 9,
      description: "9 – Outros"
    }, {
      value: 10,
      description: "10 - Deficiente condutor"
    }, {
      value: 11,
      description: "11 - Deficiente não condutor"
    }, {
      value: 12,
      description: "12 - Órgão de fomento e desenvolvimento agropecuário"
    }, {
      value: 16,
      description: "16 - Olimpíadas Rio 2016"
    }],
    icmsModalidadeBaseCalculoStValues: [{
      value: 0,
      description: "0 - Preço tabelado ou máximo sugerido"
    }, {
      value: 1,
      description: "1 - Lista Negativa (valor)"
    }, {
      value: 2,
      description: "2 - Lista Positiva (valor)"
    }, {
      value: 3,
      description: "3 - Lista Neutra (valor)"
    }, {
      value: 4,
      description: "4 - Margem Valor Agregado (%)"
    }, {
      value: 5,
      description: "5 - Pauta (valor)"
    }],
    ufValues: [{
      description: "Acre",
      value: "AC"
    }, {
      description: "Alagoas",
      value: "AL"
    }, {
      description: "Amapá",
      value: "AP"
    }, {
      description: "Amazonas",
      value: "AM"
    }, {
      description: "Bahia",
      value: "BA"
    }, {
      description: "Ceará",
      value: "CE"
    }, {
      description: "Distrito Federal",
      value: "DF"
    }, {
      description: "Espírito Santo",
      value: "ES"
    }, {
      description: "Goiás",
      value: "GO"
    }, {
      description: "Maranhão",
      value: "MA"
    }, {
      description: "Mato Grosso do Sul",
      value: "MS"
    }, {
      description: "Mato Grosso",
      value: "MT"
    }, {
      description: "Minas Gerais",
      value: "MG"
    }, {
      description: "Paraná",
      value: "PR"
    }, {
      description: "Paraíba",
      value: "PB"
    }, {
      description: "Pará",
      value: "PA"
    }, {
      description: "Pernambuco",
      value: "PE"
    }, {
      description: "Piauí",
      value: "PI"
    }, {
      description: "Rio de Janeiro",
      value: "RJ"
    }, {
      description: "Rio Grande do Norte",
      value: "RN"
    }, {
      description: "Rio Grande do Sul",
      value: "RS"
    }, {
      description: "Rondônia",
      value: "RO"
    }, {
      description: "Roraima",
      value: "RR"
    }, {
      description: "Santa Catarina",
      value: "SC"
    }, {
      description: "Sergipe",
      value: "SE"
    }, {
      description: "São Paulo",
      value: "SP"
    }, {
      description: "Tocantins",
      value: "TO"
    }],
    ipiSituacaoTributariaValues: [{
      value: '00',
      description: "00 - Entrada com recuperação de crédito"
    }, {
      value: '01',
      description: "01 - Entrada tributada com alíquota zero"
    }, {
      value: '02',
      description: "02 - Entrada isenta"
    }, {
      value: '03',
      description: "03 - Entrada não-tributada"
    }, {
      value: '04',
      description: "04 - Entrada imune"
    }, {
      value: '05',
      description: "05 - Entrada com suspensão"
    }, {
      value: '49',
      description: "49 - Outras entradas"
    }, {
      value: '50',
      description: "50 - Saída tributada"
    }, {
      value: '51',
      description: "51 - Saída tributada com alíquota zero"
    }, {
      value: '52',
      description: "52 - Saída isenta"
    }, {
      value: '53',
      description: "53 - Saída não-tributada"
    }, {
      value: '54',
      description: "54 - Saída imune"
    }, {
      value: '55',
      description: "55 - Saída com suspensão"
    }, {
      value: '99',
      description: "99 - Outras saídas"
    }],
    pisCofinsSituacaoTributariaValues: [{
      value: '01',
      description: "01 – Operação tributável - Base de Cálculo = Valor da Operação Alíquota Normal (Cumulativo/Não Cumulativo)"
    }, {
      value: '02',
      description: "02 - Operação tributável - Base de Calculo = Valor da Operação (Alíquota Diferenciada)"
    }, {
      value: '03',
      description: "03 - Operação tributável - Base de Calculo = Quantidade Vendida x Alíquota por Unidade de Produto"
    }, {
      value: '04',
      description: "04 - Operação tributável - Tributação Monofásica - (Alíquota Zero)"
    }, {
      value: '05',
      description: "05 - Operação tributável - Substituição tributária"
    }, {
      value: '06',
      description: "06 - Operação tributável - Alíquota Zero"
    }, {
      value: '07',
      description: "07 - Operação isenta da contribuição"
    }, {
      value: '08',
      description: "08 - Operação sem incidência da contribuição"
    }, {
      value: '09',
      description: "09 - Operação com suspensão da contribuição"
    }, {
      value: '49',
      description: "49 - Outras Operações de Saída"
    }, {
      value: '50',
      description: "50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"
    }, {
      value: '51',
      description: "51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"
    }, {
      value: '52',
      description: "52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"
    }, {
      value: '53',
      description: "53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"
    }, {
      value: '54',
      description: "54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"
    }, {
      value: '55',
      description: "55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"
    }, {
      value: '56',
      description: "56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"
    }, {
      value: '60',
      description: "60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"
    }, {
      value: '61',
      description: "61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"
    }, {
      value: '62',
      description: "62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"
    }, {
      value: '63',
      description: "63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"
    }, {
      value: '64',
      description: "64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"
    }, {
      value: '65',
      description: "65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"
    }, {
      value: '66',
      description: "66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"
    }, {
      value: '67',
      description: "67 - Crédito Presumido - Outras Operações"
    }, {
      value: '70',
      description: "70 - Operação de Aquisição sem Direito a Crédito"
    }, {
      value: '71',
      description: "71 - Operação de Aquisição com Isenção"
    }, {
      value: '72',
      description: "72 - Operação de Aquisição com Suspensão"
    }, {
      value: '73',
      description: "73 - Operação de Aquisição a Alíquota Zero"
    }, {
      value: '74',
      description: "74 - Operação de Aquisição sem Incidência da Contribuição"
    }, {
      value: '75',
      description: "75 - Operação de Aquisição por Substituição Tributária"
    }, {
      value: '98',
      description: "98 - Outras Operações de Entrada"
    }, {
      value: '99',
      description: "99 - Outras operações"
    }],
    issqnSituacaoTributariaValues: [{
      value: 'I',
      description: "Isenta"
    }, {
      value: 'N',
      description: "Normal"
    }, {
      value: 'R',
      description: "Retida"
    }, {
      value: 'S',
      description: "Substituída"
    }],
    veiculoTipoOperacaoValues: [{
      value: 1,
      description: "Venda concessionária"
    }, {
      value: 2,
      description: "Faturamento direto"
    }, {
      value: 3,
      description: "Venda direta"
    }, {
      value: 0,
      description: "Outros"
    }],
    veiculoCodigoCorDenatranValues: [{
      value: 1,
      description: "Amarelo"
    }, {
      value: 2,
      description: "Azul"
    }, {
      value: 3,
      description: "Bege"
    }, {
      value: 4,
      description: "Branca"
    }, {
      value: 5,
      description: "Cinza"
    }, {
      value: 6,
      description: "Dourada"
    }, {
      value: 16,
      description: "Fantasia"
    }, {
      value: 7,
      description: "Grena"
    }, {
      value: 8,
      description: "Laranja"
    }, {
      value: 9,
      description: "Marrom"
    }, {
      value: 10,
      description: "Prata"
    }, {
      value: 11,
      description: "Preta"
    }, {
      value: 12,
      description: "Rosa"
    }, {
      value: 13,
      description: "Roxa"
    }, {
      value: 14,
      description: "Verde"
    }, {
      value: 15,
      description: "Vermelha"
    }],
    veiculoTipoCombustivelValues: [{
      value: 1,
      description: "Álcool"
    }, {
      value: 12,
      description: "Álcool/Gasolina"
    }, {
      value: 13,
      description: "Álcool/Gasolina/GNV"
    }, {
      value: 9,
      description: "Álcool/GNC"
    }, {
      value: 3,
      description: "Diesel"
    }, {
      value: 10,
      description: "Diesel/GNC"
    }, {
      value: 7,
      description: "Elfont Ex"
    }, {
      value: 6,
      description: "Elfont In"
    }, {
      value: 5,
      description: "Gás metano"
    }, {
      value: 4,
      description: "Gasogênio"
    }, {
      value: 2,
      description: "Gasolina"
    }, {
      value: 8,
      description: "Gasolina/GNC"
    }],
    veiculoTipoValues: [{
      value: 6,
      description: "Automóvel"
    }, {
      value: 14,
      description: "Caminhão"
    }, {
      value: 13,
      description: "Caminhoneta"
    }, {
      value: 24,
      description: "Carga/Cam"
    }, {
      value: 2,
      description: "Ciclomoto"
    }, {
      value: 22,
      description: "Esp/Ônibus"
    }, {
      value: 7,
      description: "Microônibus"
    }, {
      value: 23,
      description: "Misto/Cam"
    }, {
      value: 4,
      description: "Motociclo"
    }, {
      value: 3,
      description: "Motoneta"
    }, {
      value: 8,
      description: "Ônibus"
    }, {
      value: 10,
      description: "Reboque"
    }, {
      value: 5,
      description: "Triciclo"
    }, {
      value: 17,
      description: "C. trator"
    }],
    veiculoEspecieValues: [{
      value: 2,
      description: "Carga"
    }, {
      value: 4,
      description: "Corrida"
    }, {
      value: 6,
      description: "Especial"
    }, {
      value: 3,
      description: "Misto"
    }, {
      value: 1,
      description: "Passageiro"
    }, {
      value: 5,
      description: "Tração"
    }],
    veiculoCodigoVinValues: [{
      value: 'N',
      description: "Normal"
    }, {
      value: 'R',
      description: "Remarcado"
    }],
    veiculoCondicaoValues: [{
      value: 1,
      description: "Acabado"
    }, {
      value: 2,
      description: "Inacabado"
    }, {
      value: 3,
      description: "Semi-acabado"
    }],
    veiculoRestricaoValues: [{
      value: 0,
      description: "Não há"
    }, {
      value: 1,
      description: "Alienação fiduciária"
    }, {
      value: 2,
      description: "Arrendamento mercantil"
    }, {
      value: 3,
      description: "Reserva de domínio"
    }, {
      value: 4,
      description: "Penhor de veículos"
    }, {
      value: 9,
      description: "Outras"
    }],
    viaTransporteInternacionalValues: [{
      value: 1,
      description: "1 – Marítima"
    }, {
      value: 2,
      description: "2 - Fluvial"
    }, {
      value: 3,
      description: "3 - Lacustre"
    }, {
      value: 4,
      description: "4 - Aérea"
    }, {
      value: 5,
      description: "5 - Postal"
    }, {
      value: 6,
      description: "6 - Ferroviária"
    }, {
      value: 7,
      description: "7 - Rodoviária"
    }, {
      value: 8,
      description: "8 - Conduto / Redes transmissão"
    }, {
      value: 9,
      description: "9 - Meios próprios"
    }, {
      value: 10,
      description: "10 - Entrada / Saída ficta"
    }, {
      value: 11,
      description: "11 - Courier"
    }, {
      value: 12,
      description: "12 - Handcarry"
    }],
    formaIntermedioValues: [{
      value: 1,
      description: "1 – Importação por conta própria"
    }, {
      value: 2,
      description: "2 - Importação por conta e ordem"
    }, {
      value: 3,
      description: "3 - Importação por encomenda"
    }],
    presencaCompradorValues: [{
      description: "Não se aplica (ex: NF-e complementar ou de ajuste)",
      value: 0
    }, {
      description: "Operação presencial",
      value: 1
    }, {
      description: "Operação não presencial, pela Internet",
      value: 2
    }, {
      description: "Operação não presencial, Teleatendimento",
      value: 3
    }, {
      description: "Operação presencial, fora do estabelecimento",
      value: 5
    }, {
      description: "Operação não presencial, outros",
      value: 9
    }],
    localDestinoValues: [{
      description: "Calcular automaticamente",
      value: 0
    }, {
      description: "Operação interna",
      value: 1
    }, {
      description: "Operação interestadual",
      value: 2
    }, {
      description: "Operação com exterior",
      value: 3
    }],
    finalidadeEmissaoValues: [{
      value: 1,
      description: "NF-e normal"
    }, {
      value: 2,
      description: "NF-e complementar"
    }, {
      value: 3,
      description: "NF-e de ajuste"
    }, {
      value: 4,
      description: "Devolução de mercadoria"
    }],
    tipoDocumentoValues: [{
      value: 0,
      description: 'Entrada'
    }, {
      value: 1,
      description: 'Saída'
    }],
    formaPagamentoValues: [{
      value: '0',
      description: 'À vista'
    }, {
      value: '1',
      description: 'À prazo'
    }],
    posReportFileTypePossibleValues: [{
      value: 'VENDA',
      description: 'Vendas'
    }, {
      value: 'DANFE_NFCE',
      description: 'DANFe NFCe'
    }, {
      value: 'SAT_CFE',
      description: 'CFe S@T'
    }, {
      value: 'LISTA_VENDAS',
      description: 'Listagem de Vendas'
    }, {
      value: 'CARNE',
      description: 'Carnê'
    }, {
      value: 'MOVIMENTACAO_DIARIA',
      description: 'Movimentação Diária'
    }, {
      value: 'RECIBO',
      description: 'Recibo'
    }, {
      value: 'FECHAMENTO_CAIXA',
      description: 'Fechamento de Caixa'
    }, {
      value: 'CONSIGNACAO',
      description: 'Consignação'
    }, {
      value: 'LISTA_CONSIGNACOES_ABERTAS',
      description: 'Lista de Consignações Abertas'
    }],
    checkStatusValues: [{
      value: 'A',
      description: 'Em aberto'
    }, {
      value: 'E',
      description: 'Cancelado'
    }, {
      value: 'C',
      description: 'Compensado'
    }, {
      value: 'D',
      description: 'Devolvido'
    }, {
      value: 'U',
      description: 'Custodiado'
    }, {
      value: 'N',
      description: 'Negociado'
    }],
    modalidadeFreteValues: [{
      value: 0,
      description: "Por conta do emitente"
    }, {
      value: 1,
      description: "Por conta do destinatário/remetente"
    }, {
      value: 2,
      description: "Por conta de terceiros"
    }, {
      value: 9,
      description: "Sem frete"
    }],
    veiculoValues: [{
      value: 'veiculo',
      description: "Veículo"
    }, {
      value: 'vagao',
      description: "Vagão"
    }, {
      value: 'balsa',
      description: "Balsa"
    }],
    tipoVendaValues: [{
      description: "Automático",
      value: "automatico"
    }, {
      description: "Uso/Consumo",
      value: "venda_consumidor"
    }, {
      description: "Revenda",
      value: "revenda"
    }, {
      description: "Industrialização",
      value: "industrializacao"
    }],
    tiposNotaReferenciada: [{
      description: 'NFe interna',
      value: 'nfe_interna'
    }, {
      description: 'NFe externa',
      value: 'nfe_externa'
    }, {
      description: 'Venda NFCe',
      value: 'nfce'
    }, {
      description: 'NF modelo 1/1A',
      value: 'nf_um_ou_um_a'
    }, {
      description: 'NF de produtor',
      value: 'nf_produtor'
    }, {
      description: 'Cupom fiscal',
      value: 'cupom_fiscal'
    }, {
      description: 'Conhecimento de transporte eletrônico (CTe)',
      value: 'cte'
    }],
    transferStatuses: [{
      description: 'Confirmado',
      value: 'realized'
    }, {
      description: 'Em trânsito',
      value: 'in_transit'
    }, {
      description: 'Cancelado',
      value: 'canceled'
    }],
    promotionItemTypes: [{
      description: 'Produto',
      value: 'Product'
    }, {
      description: 'Tipo de produto',
      value: 'ProductType'
    }, {
      description: 'Departamento',
      value: 'Department'
    }, {
      description: 'Coleção',
      value: 'Collection'
    }, {
      description: 'Fornecedor',
      value: 'Supplier'
    }],
    promotionDiscountTypes: [{
      description: 'Informar novo preço dos produtos',
      value: 'price'
    }],
    contractTypes: [{
      description: 'DFe',
      value: 'DFE'
    }, {
      description: 'Revenda NFe',
      value: 'REVENDANFE'
    }, {
      description: 'CTe',
      value: 'CTE'
    }, {
      description: 'MDe',
      value: 'MDE'
    }, {
      description: 'Lojas',
      value: 'LOJAS'
    }, {
      description: 'API_NFCe',
      value: 'APINFCE'
    }, {
      description: 'Tela_NFCe',
      value: 'TELANFCE'
    }, {
      description: 'ERP_Basico',
      value: 'ERPBASICO'
    }, {
      description: 'Outros',
      value: 'OUTROS'
    }],
    contractStatusValues: [{
      value: 'S',
      description: 'Started'
    }, {
      value: 'A',
      description: 'Active'
    }, {
      value: 'C',
      description: 'Canceled'
    }, {
      value: 'F',
      description: 'Sale_Failed'
    }],
    waybillStatusValues: [{
      description: 'Em andamento',
      value: 'ONGOING'
    }, {
      description: 'Realizado',
      value: 'COMPLETED'
    }, {
      description: 'Não finalizado',
      value: 'UNFINISHED'
    }, {
      description: 'Cancelado',
      value: 'CANCELED'
    }],
    waybillStatusValuesForSearch: [{
      description: 'Em andamento',
      value: 'ONGOING'
    }, {
      description: 'Realizado',
      value: 'COMPLETED'
    }],
    issqnIndicadoresExibilidade: [{
      description: 'Exigível',
      value: 1
    }, {
      description: 'Não incidência',
      value: 2
    }, {
      description: 'Isenção',
      value: 3
    }, {
      description: 'Exportação',
      value: 4
    }, {
      description: 'Imunidade',
      value: 5
    }, {
      description: 'Exigibilidade Suspensa por Decisão Judicial',
      value: 6
    }, {
      description: 'Exigibilidade Suspensa por Processo Administrativo',
      value: 7
    }],
    priceTypes: [{
      description: 'Atacado',
      value: 'WHOLESALE'
    }, {
      description: 'Varejo',
      value: 'RETAIL'
    }],
    genders: [{
      description: 'Feminino',
      value: 'F'
    }, {
      description: 'Masculino',
      value: 'M'
    }],
    getPriceTypeLabel: function getPriceTypeLabel(value) {
      var i, item, len, ref;
      ref = this.get('priceTypes');

      for (i = 0, len = ref.length; i < len; i++) {
        item = ref[i];

        if (item.value === value) {
          return item.description;
        }
      }
    },
    monthOptions: [{
      description: "Janeiro",
      value: 1
    }, {
      description: "Fevereiro",
      value: 2
    }, {
      description: "Março",
      value: 3
    }, {
      description: "Abril",
      value: 4
    }, {
      description: "Maio",
      value: 5
    }, {
      description: "Junho",
      value: 6
    }, {
      description: "Julho",
      value: 7
    }, {
      description: "Agosto",
      value: 8
    }, {
      description: "Setembro",
      value: 9
    }, {
      description: "Outubro",
      value: 10
    }, {
      description: "Novembro",
      value: 11
    }, {
      description: "Dezembro",
      value: 12
    }],
    maritalStatusOptions: [{
      description: 'Solteiro(a)',
      value: 'S'
    }, {
      description: 'Casado(a)',
      value: 'C'
    }, {
      description: 'Divorciado(a)',
      value: 'D'
    }, {
      description: 'Viúvo(a)',
      value: 'V'
    }],
    residenceTypeOptions: [{
      description: 'Própria',
      value: 'P'
    }, {
      description: 'Alugada',
      value: 'A'
    }, {
      description: 'Emprestada',
      value: 'E'
    }, {
      description: 'Outros',
      value: 'O'
    }],
    taxTypes: [{
      value: "T",
      description: 'Tributado pelo ICMS'
    }, {
      value: "N",
      description: 'Não Tributado'
    }, {
      value: "I",
      description: 'Isento'
    }, {
      value: "F",
      description: 'Substituição Tributária'
    }],
    nfeIssueOption: [{
      description: "Antes do Pagamento",
      value: 'before_payment'
    }, {
      description: "Depois do Pagamento",
      value: 'after_payment'
    }, {
      description: "Manualmente",
      value: 'manually'
    }],
    institutions: [{
      description: 'Hospital Pequeno Príncipe',
      value: 'HPP'
    }],
    pafPerfils: [{
      description: 'V',
      value: "V"
    }, {
      description: 'N/D',
      value: "N/D"
    }],
    accessPermissions: [{
      description: "Somente Administrador",
      value: 0
    }, {
      description: "Administrador e Gerente",
      value: 1
    }, {
      description: "Administrador, Gerente e Supervisor",
      value: 2
    }, {
      description: "Sem restrição",
      value: 3
    }],
    accountOrigins: [{
      description: "01 - Contas de ativo",
      value: "01"
    }, {
      description: "02 - Contas de passivo",
      value: "02"
    }, {
      description: "03 - Patrimônio líquido",
      value: "03"
    }, {
      description: "04 - Contas de resultado",
      value: "04"
    }, {
      description: "05 - Contas de compensação",
      value: "05"
    }, {
      description: "09 - Outras",
      value: "09"
    }],
    pdvCodeInputMethods: [{
      description: 'Caixa de busca Inteligente',
      value: 'OMNIBOX'
    }, {
      description: 'Somente Código de Barras',
      value: 'BARCODE'
    }],
    satModels: [{
      description: 'Bematech RB2000',
      value: 'bematech'
    }, {
      description: 'Bematech Sat Go',
      value: 'bematech_sat_go'
    }, {
      description: 'Control ID',
      value: 'control_id'
    }, {
      description: 'Dimep',
      value: 'dimep'
    }, {
      description: 'EPSON A10',
      value: 'epson'
    }, {
      description: 'TANCA MFE TM-1000',
      value: 'tanca'
    }, {
      description: 'TANCA SAT TS-1000',
      value: 'tanca_sat'
    }, {
      description: 'GERTEC GerMFE',
      value: 'germfe'
    }, {
      description: 'Sweda SS-2000',
      value: 'sweda'
    }, {
      description: 'Elgin Smart SAT',
      value: 'elgin'
    }],
    customerRewardsModes: [{
      description: 'Não gera créditos',
      value: 'NONE'
    }, {
      description: 'Um ponto por Real Gasto',
      value: 'ONE_PER_REAL'
    }],
    accountTypeIndicators: [{
      description: "S - Sintética (grupo de contas)",
      value: "S"
    }, {
      description: "A - Analítica (conta)",
      value: "A"
    }],
    printLocations: [{
      description: "Empresa",
      value: "impressao_empresa"
    }, {
      description: "Banco",
      value: "impressao_banco"
    }],
    paymentTypes: [{
      description: 'Todos',
      value: 'all'
    }, {
      description: 'Dinheiro',
      value: 'cash'
    }, {
      description: 'Cartão à vista',
      value: 'cardCash'
    }, {
      description: 'Cartão à prazo',
      value: 'card'
    }, {
      description: 'Cheque à vista',
      value: 'checkCash'
    }, {
      description: 'Cheque à prazo',
      value: 'check'
    }, {
      description: 'Carnê',
      value: 'carnet'
    }, {
      description: 'Entradas',
      value: 'entries'
    }, {
      description: 'Outros',
      value: 'others'
    }],
    pdvPaymentTypes: [{
      description: 'Dinheiro',
      value: 'Dinheiro'
    }, {
      description: 'Cartão',
      value: 'Cartão'
    }, {
      description: 'Cheque',
      value: 'Cheque'
    }, {
      description: 'Carnê',
      value: 'Carnê'
    }],
    inputComponentTypes: {
      TOGGLE: 'inputs/input-toggle',
      TOGGLE_GROUP: 'inputs/input-toggle-group',
      STRING: 'inputs/base-text-field',
      NUMBER: 'inputs/input-number',
      PERCENTAGE: 'inputs/input-percentage',
      MONEY: 'inputs/input-money',
      DATE: 'inputs/input-date',
      TIME: 'inputs/input-time',
      PERIOD: 'inputs/input-period',
      RADIO: 'inputs/input-radio-group',
      AUTOCOMPLETE: 'inputs/input-autocomplete',
      SELECT: 'inputs/input-select',
      CPF: 'inputs/input-cpf',
      CNPJ: 'inputs/input-cnpj',
      CEP: 'inputs/input-cep',
      EMAIL: 'inputs/input-email',
      PHONE: 'inputs/input-phone',
      PRODUCT_LIST: 'inputs/product/product-list'
    },
    reports: {
      notas_fiscais_consumidor_report: {
        title: 'Listagem de Notas Fiscais',
        param: 'notas_fiscais_consumidor_params',
        url: 'listagem_notas_fiscais',
        allowXls: true
      },
      notas_fiscais_report: {
        title: 'Listagem de Notas Fiscais',
        param: 'notas_fiscais_params',
        url: 'listagem_notas_fiscais',
        allowXls: true
      },
      boleto_nfe_report: {
        title: 'Lista de Boletos por Nota Fiscal',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'BoletoNfeReport',
        allowCsv: true,
        allowPdf: true
      },
      inventory_report: {
        title: 'Balanço de Estoque',
        param: 'inventory_params',
        url: 'inventories_report',
        allowCsv: true,
        allowPdf: true
      },
      product_rank_nfe_report: {
        title: 'Ranking de Produtos NFe',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'ProductRankNfeReport',
        allowCsv: true,
        allowPdf: true
      },
      labels_report: {
        title: 'Emissão de Etiquetas',
        param: 'labels_params',
        url: '/labels_report',
        is_label_report: true
      },
      product_rank_report: {
        title: 'Ranking de Produtos',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'ProductRankReport',
        allowCsv: true,
        allowPdf: true
      },
      sale_report: {
        title: 'Balanço de Vendas',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'SaleReport',
        allowCsv: true,
        allowPdf: true
      },
      sellers_rank_report: {
        title: 'Ranking de Vendedores',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'SellersRankReport',
        allowCsv: true,
        allowPdf: true
      },
      sales_by_seller_by_installment_type_report: {
        title: 'Vendas por Vendedor (À Vista/Prazo)',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'SalesBySellerByInstallmentTypeReport',
        allowCsv: true,
        allowPdf: true
      },
      sales_by_supplier_report: {
        title: 'Ranking de Fornecedores',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'SalesBySupplierReport',
        allowCsv: true,
        allowPdf: true
      },
      product_type_sales_report: {
        title: 'Venda por Tipo de Produto',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'ProductTypeSalesReport',
        allowCsv: true,
        allowPdf: true
      },
      sales_by_card_company_report: {
        title: 'Venda por Credenciadora',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'SalesByCardCompanyReport',
        allowPdf: true
      },
      kit_sales_report: {
        title: 'Venda de Kits',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'KitSalesReport',
        allowCsv: true,
        allowPdf: true
      },
      returned_products_report: {
        title: 'Produtos Devolvidos',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'ReturnedProducts',
        allowCsv: true,
        allowPdf: true
      },
      customer_report: {
        title: 'Lista de Clientes',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'CustomerReport',
        allowCsv: true,
        allowPdf: true
      },
      customer_rank_report: {
        title: 'Ranking de Clientes',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'CustomerRankReport',
        allowCsv: true,
        allowPdf: true
      },
      customer_xray_report: {
        title: 'Raio X de Cliente',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'CustomerXRayReport',
        allowPdf: true
      },
      pos_entries_report: {
        title: 'Recebimentos por Forma de Pagamento',
        param: 'pos_entries_params',
        url: 'pos_entries_report',
        allowCsv: true,
        allowPdf: true
      },
      delayed_receivables_report: {
        title: 'Recebimentos em Atraso',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'DelayedReceivablesReport',
        allowCsv: true,
        allowPdf: true
      },
      pending_receivables_report: {
        title: 'Recebimentos Futuros',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'PendingReceivablesReport',
        allowCsv: true,
        allowPdf: true
      },
      customer_delayed_letter_report: {
        title: 'Carta de Atraso ao cliente',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'CustomerDelayedLetterReport',
        allowCsv: false,
        allowPdf: true
      },
      supplier_payment_report: {
        title: 'Pagamentos por Fornecedor',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'SupplierPaymentReport',
        allowCsv: true,
        allowPdf: true
      },
      payment_byduedate_report: {
        title: 'Pagamentos por Período de Vencimento',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'PaymentByDueDateReport',
        allowCsv: true,
        allowPdf: true
      },
      payment_by_paydate_report: {
        title: 'Pagamentos por Período de Pagamento',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'PaymentByPayDateReport',
        allowCsv: true,
        allowPdf: true
      },
      cash_flow_report: {
        title: 'Provisão de Fluxo de Caixa',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'CashFlowReport',
        allowCsv: true,
        allowPdf: true
      },
      carnet_overview_report: {
        title: 'Situação dos Recebimento em Carnê',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'CarnetOverviewReport',
        allowPdf: true
      },
      dre_gerencial_report: {
        title: 'DRE Gerencial',
        param: 'dre_gerencial_params',
        url: 'relatorios/dre.json',
        type: 'DreGerencialReport',
        is_dre_report: true
      },
      reducao_z_report: {
        title: 'Reduções Z',
        param: 'reducao_z_params',
        url: 'z_reductions',
        type: 'ReducaoZReport',
        is_reducao_z_report: true
      },
      fiscal_check_pendencies_report: {
        title: 'Pendências Fiscais',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'FiscalCheckPendenciesReport',
        allowPdf: true
      },
      cat52_report: {
        title: 'Arquivo NF Paulista / Gaúcha / CAT 52 / Ato Cotepe 17/04',
        param: 'cat52_params',
        url: 'reducoes_z/cat_52',
        allowTxt: true
      },
      sintegra_report: {
        title: 'Arquivo Sintegra',
        param: 'sintegra_params',
        url: 'sales/sintegra',
        allowTxt: true
      },
      sped_report: {
        title: 'Arquivo SPED Fiscal',
        param: 'sped_params',
        url: 'sales/sped',
        allowTxt: true
      },
      manual_exits_report: {
        title: 'Saídas Manuais de Mercadoria',
        param: 'get_filters_params',
        url: 'generate_report',
        type: 'ManualExitsReport',
        allowPdf: true
      }
    },
    regimesTributarios: [{
      description: "Simples Nacional",
      value: '1'
    }, {
      description: "Simples Nacional - excesso de sublimite de receita bruta",
      value: '2'
    }, {
      description: "Regime Normal",
      value: '3'
    }],
    modelosLancamentoTypes: [{
      description: 'Pagar',
      value: 'DESPESA'
    }, {
      description: 'Receber',
      value: 'RECEITA'
    }],
    modelosLancamentoDataCompetenciaMesAnteriorValues: [{
      description: 'Considerar data de competência como o mês anterior à data de vencimento',
      value: true
    }, {
      description: 'Considerar data de competência como a data de vencimento',
      value: false
    }],
    giftCouponStatus: [{
      description: 'Válido',
      value: 'valid'
    }, {
      description: 'Cancelado',
      value: 'void'
    }, {
      description: 'Utilizado',
      value: 'used'
    }],
    posUserStatus: [{
      description: 'Ativo',
      value: 'active'
    }, {
      description: 'Inativo',
      value: 'inactive'
    }],
    roundOptionValues: [{
      description: 'Nenhum',
      value: 'NOROUND'
    }, {
      description: 'Inteiro mais próximo',
      value: 'ROUNDNEARESTINT'
    }, {
      description: 'Para Cima',
      value: 'ROUNDUP'
    }, {
      description: 'Para Baixo',
      value: 'ROUNDDOWN'
    }],
    saleStatusValues: [{
      description: 'Realizada',
      value: 'realized'
    }, {
      description: 'Cancelada',
      value: 'canceled'
    }, {
      description: 'Cancelada no PDV',
      value: 'canceled_pos'
    }, {
      description: 'Não finalizada',
      value: 'unfinished'
    }],
    nfseNaturezaOperacaoValues: {
      iss_sp: [{
        value: 'T',
        description: 'Operação normal (tributação conforme documento emitido)'
      }, {
        value: 'I',
        description: 'Operação isenta ou não tributável, executadas no Município de São Paulo'
      }, {
        value: 'F',
        description: 'Operação isenta ou não tributável pelo Município de São Paulo, executada em outro Município'
      }, {
        value: 'J',
        description: 'ISS Suspenso por Decisão Judicial'
      }],
      abrasf: [{
        value: '1',
        description: 'Tributação no município'
      }, {
        value: '2',
        description: 'Tributação fora do município'
      }, {
        value: '3',
        description: 'Isenção'
      }, {
        value: '4',
        description: 'Imune'
      }, {
        value: '5',
        description: 'Exigibilidade suspensa por decisão judicial'
      }, {
        value: '6',
        description: 'Exigibilidade suspensa por procedimento administrativo'
      }]
    },
    modelo1AOptions: [{
      label: "01",
      value: "01"
    }],
    modeloNfProdutorOptions: [{
      label: "01 - NF avulsa",
      value: "01"
    }, {
      label: "04 - NF de produtor",
      value: "04"
    }],
    modeloCupomFiscalOptions: [{
      label: "2B - cupom fiscal emitido por máquina registradora (não ECF)",
      value: "2B"
    }, {
      label: "2C - cupom fiscal emitido por PDV",
      value: "2C"
    }, {
      label: "2D - cupom fiscal emitido por ECF",
      value: "2D"
    }],
    faturaParcelamentoTiposIntervalo: [{
      value: 'dias',
      label: 'dia(s)'
    }, {
      value: 'semanas',
      label: 'semana(s)'
    }, {
      value: 'meses',
      label: 'mês(es)'
    }],
    saleCheckoutPaymentTypes: {
      CASH: {
        value: 'Dinheiro',
        description: 'Dinheiro'
      },
      CARD: {
        value: 'Cartão',
        description: 'Cartão'
      },
      TEF: {
        value: 'TEF',
        description: 'TEF'
      },
      CHECK: {
        value: 'Cheque',
        description: 'Cheque'
      },
      CARNET: {
        value: 'Carnê',
        description: 'Carnê'
      },
      OTHERS: {
        value: 'Outros (imp.)',
        description: 'Outros'
      },
      GIFT_COUPON_RETURN: {
        value: 'GiftCouponReturn',
        description: 'Vale'
      },
      FOOD_COUPON: {
        value: 'ValeRefeicao',
        description: 'Vale Refeição'
      },
      GROCERY_COUPON: {
        value: 'ValeAlimentacao',
        description: 'Vale Alimentação'
      },
      FUEL_COUPON: {
        value: 'ValeCombustivel',
        description: 'Vale Combustível'
      },
      GIFT_COUPON: {
        value: 'GiftCoupon',
        description: 'Vale Presente'
      }
    },
    nfePaymentTypes: {
      CASH: {
        value: 'Dinheiro',
        description: 'Dinheiro'
      },
      CHECK: {
        value: 'Cheque',
        description: 'Cheque'
      },
      CREDIT_CARD: {
        value: 'CartãoCredito',
        description: 'Cartão de Crédito'
      },
      DEBIT_CARD: {
        value: 'CartãoDebito',
        description: 'Cartão de Débito'
      },
      CREDIT_STORE: {
        value: 'CréditoLoja',
        description: 'Crédito Loja'
      },
      GROCERY_COUPON: {
        value: 'ValeAlimentacao',
        description: 'Vale Alimentação'
      },
      FOOD_COUPON: {
        value: 'ValeRefeicao',
        description: 'Vale Refeição'
      },
      GIFT_COUPON: {
        value: 'GiftCoupon',
        description: 'Vale Presente'
      },
      FUEL_COUPON: {
        value: 'ValeCombustivel',
        description: 'Vale Combustível'
      },
      BANK_SLIP: {
        value: 'BoletoBancário',
        description: 'Boleto Bancárioo'
      },
      BANK_DEPOSIT: {
        value: 'DepósitoBancário',
        description: 'Depósito Bancário'
      },
      PIX: {
        value: 'PIX',
        description: 'Pagamento Instantâneo (PIX)'
      },
      BANK_TRANSFER: {
        value: 'TransferênciaBancária',
        description: 'Transferência bancária,  Carteira Digital'
      },
      CASHBACK: {
        value: 'Cashback',
        description: 'Programa  de fidelidade, Cashback, Crédito Virtual'
      },
      NO_PAYMENT: {
        value: 'SemPagamento',
        description: 'Sem pagamento'
      },
      OTHERS: {
        value: 'Outros (imp.)',
        description: 'Outros'
      }
    },
    inventoryAdjustmentType: [{
      value: 0,
      description: 'Entrada'
    }, {
      value: 1,
      description: 'Saída'
    }],
    contaContabilStatus: [{
      value: 'ATIVO',
      description: 'Ativo'
    }, {
      value: 'INATIVO',
      description: 'Inativo'
    }],
    lancamentoSearchTypes: [{
      value: '',
      description: 'Entradas e Saídas'
    }, {
      value: 'RECEITA',
      description: 'Somente Entradas'
    }, {
      value: 'DESPESA',
      description: 'Somente Saídas'
    }],
    lancamentoSearchDateTypes: [{
      value: 'data_vencimento',
      description: 'Data de Vencimento'
    }, {
      value: 'data_realizado',
      description: 'Data de Pagamento/recebimento'
    }, {
      value: 'data_competencia',
      description: 'Data de Competência'
    }],
    lancamentoSearchSituacao: [{
      value: '383',
      description: 'Todos'
    }, {
      value: '124',
      description: 'Somente em Aberto'
    }, {
      value: '3',
      description: 'Somente Baixados'
    }, {
      value: '256',
      description: 'Somente Cancelados'
    }],
    lancamentoSearchPaymentType: [{
      value: '',
      description: 'Todos'
    }, {
      value: 'Carnê',
      description: 'Carnê'
    }, {
      value: 'Cheque',
      description: 'Cheque'
    }, {
      value: 'TEF-D',
      description: 'TEF de Débito'
    }, {
      value: 'TEF-C',
      description: 'TEF de Crédito'
    }, {
      value: 'Cartão-D',
      description: 'Cartão de Débito'
    }, {
      value: 'Cartão-C',
      description: 'Cartão de Crédito'
    }, {
      value: 'Dinheiro',
      description: 'Dinheiro'
    }, {
      value: 'manual',
      description: 'Entrada manual'
    }],
    lancamentoTypes: [{
      description: 'Pagamento',
      value: 'DESPESA'
    }, {
      description: 'Recebimento',
      value: 'RECEITA'
    }],
    branchActivityValues: [{
      value: 'ERP_SAAS',
      description: 'ERP SaaS'
    }, {
      value: 'ERP_INTERNO',
      description: 'ERP Interno'
    }, {
      value: 'ERP_VALOR_AGREGADO',
      description: 'ERP Valor Agregado'
    }, {
      value: 'ECOMMERCE_PROPRIO',
      description: 'eCommerce Próprio'
    }, {
      value: 'ECOMMERCE_SAAS',
      description: 'eCommerce SaaS'
    }, {
      value: 'INTEGRACOES',
      description: 'Integrações'
    }, {
      value: 'EMISSOR',
      description: 'Emissor'
    }, {
      value: 'SW_HOUSE',
      description: 'SW House'
    }]
  });

  _exports.default = _default;
});
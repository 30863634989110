define("api-dashboard/templates/components/kits/kit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tD0plhpl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Novo\"]],\"parameters\":[]},null],[0,\" Kit\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"base/base-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],[24,[\"onSubmit\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onCancel\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"cols\"],[\"Nome\",[24,[\"model\",\"name\"]],4]]],false],[0,\"\\n      \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"cols\"],[\"Código\",[24,[\"model\",\"code\"]],4]]],false],[0,\"\\n      \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"cols\"],[\"Código de Barras\",[24,[\"model\",\"barcode\"]],4]]],false],[0,\"\\n      \"],[1,[28,\"inputs/input-toggle\",null,[[\"label\",\"value\",\"cols\"],[\"Ativo?\",[24,[\"model\",\"active\"]],4]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[1,[28,\"inputs/product/product-list\",null,[[\"items\",\"inputType\",\"modelType\",\"showQuantity\",\"showQuantityBefore\",\"showPrice\"],[[24,[\"model\",\"items\"]],\"autocomplete-only\",\"kit-item\",true,true,true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/kits/kit-form.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/transfers/transfer-form", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    application: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    multipleLabelModels: Ember.computed(function () {
      var labelModels;
      labelModels = this.get('application.currentDomain.labelModels');
      return labelModels != null && labelModels.get('length') > 1;
    }),
    printLabelsDisabled: Ember.computed('labelModel', 'multipleLabelModels', function () {
      return this.get('multipleLabelModels') && !this.get('labelModel');
    }),
    statusReceived: Ember.computed('model.status', 'model.isDirty', function () {
      return this.get('model.status') === 'realized' && !this.get('model.isDirty');
    }),
    statusInTransit: Ember.computed('status', 'model.isDirty', function () {
      return this.get('model.status') === 'in_transit' && !this.get('model.isDirty');
    }),
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      confirmTransfer: function confirmTransfer() {
        this.set('model.status', 'realized');
        return this.get('model').save().then(function (obj) {
          self.get('application').showAlert('Recebimento cancelado com sucesso!', 'success');
          return self.set('spin', false);
        }, function () {
          self.get('application').showAlert('Erro ao cancelar o recebimento. Por favor contate o suporte técnico', 'error');
          return self.set('spin', false);
        });
      },
      printLabels: function printLabels() {
        var date, ext, filename, labelModel, loja1, loja2, self, url;
        self = this;
        self.set('loading', true);
        url = '/labels_report?trade_id=' + this.get('model.trade.id');
        labelModel = this.get('labelModel');

        if (labelModel != null) {
          url += '&label_model_id=' + labelModel.get('id');
        }

        date = self.get('model.trade.entryDate');
        date = date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear();
        loja1 = self.get('model.trade.originStore.name');
        loja2 = self.get('model.trade.destinationStore.name');
        ext = self.get('labelModel.fileExtension') || 'pdf';
        filename = "etiquetas_transeferencia_" + loja1 + "_" + loja2 + "_" + date + "." + ext;
        return this.downloadURL(url, filename, function () {
          return self.set('loading', false);
        });
      },
      print: function print() {
        var filename, self, url;
        url = '/transfers/' + this.get('model.id') + "/report";
        filename = "transferencia_" + this.get('model.number') + ".pdf";
        self = this;
        return this.downloadURL(url, filename, function () {
          return self.set('loading', false);
        });
      },
      cancelTransfer: function cancelTransfer() {
        if (confirm("Cancelar Recebimento?")) {
          this.set('model.status', 'canceled');
          return this.get('model').save().then(function (obj) {
            self.get('application').showAlert('Recebimento cancelado com sucesso!', 'success');
            return self.set('spin', false);
          }, function () {
            self.get('application').showAlert('Erro ao cancelar o recebimento. Por favor contate o suporte técnico', 'error');
            return self.set('spin', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/components/card-company-configs/enabled-card-brand-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmya4pMI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"inputs/input-toggle\",[],[[\"@label\",\"@value\",\"@cols\"],[[24,[\"fStore\",\"name\"]],[22,\"checked\"],\"4\"]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/card-company-configs/enabled-card-brand-store.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/gift-coupons/gift-coupon-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      return this._getCouponBarcode();
    },
    _getCouponBarcode: function _getCouponBarcode() {
      var self;
      self = this;
      return Ember.run.schedule("afterRender", self, function () {
        return JsBarcode("#gift_coupon_barcode", self.get('model.code'), {
          height: 40,
          displayValue: false
        });
      });
    },
    isInputDisabled: Ember.computed('model', 'models.isNew', function () {
      return !this.get('model.isNew');
    }),
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/cash-drawers/after-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G9DYx6gO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"clear-25\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 text-right\"],[8],[0,\"\\n    \"],[1,[28,\"base/spin-button\",null,[[\"buttonText\",\"icon\",\"action\"],[\"Download CSV\",\"fa-file-csv\",\"downloadAllCashDrawersCSV\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/cash-drawers/after-search.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/cash-drawers/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X7N5wvX1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"apiPanel__button--box\"],[8],[0,\"\\n  \"],[1,[28,\"base/spin-button\",null,[[\"buttonText\",\"action\"],[\"Download\",[28,\"action\",[[23,0,[]],\"downloadSingleCashDrawerCSV\",[24,[\"item\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/cash-drawers/actions.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/routes/index", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel", "api-dashboard/mixins/requests/util"], function (_exports, _rotaAutenticavel, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend(_util.default, {
    application: Ember.inject.service(),
    model: function model() {
      var self;
      self = this;
      return null;
    }
  });

  _exports.default = _default;
});
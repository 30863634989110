define("api-dashboard/routes/checks/edit", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').findRecord('check', params['id'], {
        reload: true
      });
    },
    actions: {
      afterSave: function afterSave(record) {
        return this.transitionTo('checks/index');
      },
      onCancel: function onCancel(record) {
        return this.transitionTo('checks/index');
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/dimensions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AQExjoUD",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"dimensions/dimension-form\",null,[[\"model\",\"onSubmit\",\"afterSave\",\"onCancel\"],[[24,[\"model\"]],\"onSubmit\",\"afterSave\",\"onCancel\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/dimensions/edit.hbs"
    }
  });

  _exports.default = _default;
});
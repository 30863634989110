define("api-dashboard/components/promotions/promotion-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    _applyDiscountForDisabled: false,
    _productListColumns: ['brand', 'code', 'description', 'priceWithoutDiscount', 'priceWithDiscount'],
    init: function init() {
      return this._super.apply(this, arguments);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      deleteItem: function deleteItem(item) {
        var items;

        if (item.get('isNew') != null) {
          items = this.get('model.items');
          items.removeObject(item);
          return item.deleteRecord();
        } else {
          return item.set('_destroy', true);
        }
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/models/manual-entry", ["exports", "ember-data", "api-dashboard/models/trade"], function (_exports, _emberData, _trade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trade.default.extend({});

  _exports.default = _default;
});
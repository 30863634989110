define("api-dashboard/components/checks/check-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    constants: Ember.inject.service(),
    model: null,
    searchModelName: 'check',
    title: 'Cheque',
    titlePlural: 'Cheques',
    newRoute: 'checks/new',
    editRoute: 'checks/edit',
    partialStats: null,
    nextRecordsVisible: true,
    showFilters: true,
    showRefinedSearch: true,
    allowDelete: false,
    showNoRecordsLayout: false,
    columns: [{
      field: 'date',
      label: 'Data',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY'
      }
    }, {
      field: 'value',
      label: 'Valor',
      format: 'currency'
    }, {
      field: 'name',
      label: 'Nome'
    }, {
      field: 'statusDescription',
      label: 'Situação'
    }, {
      field: 'comment',
      label: 'Observação'
    }],
    searchParams: Ember.computed(function () {
      return [{
        label: 'Nome',
        field: 'name',
        param: 'name',
        value: ''
      }, {
        label: 'Status',
        field: 'stauts',
        param: 'status',
        displayField: 'description',
        valueField: 'value',
        component: 'inputs/input-select',
        options: this.get('constants.checkStatusValues'),
        value: null
      }, {
        label: 'Data De',
        field: 'createdAt',
        param: 'date_from_js',
        component: 'inputs/input-date'
      }, {
        label: 'Data Até',
        field: 'createdAt',
        param: 'date_to_js',
        component: 'inputs/input-date'
      }, {
        label: 'Ordenação Registros (Não mostrar ao usuário)',
        param: 'order_ascending',
        value: true,
        visible: false
      }, {
        label: 'Ordenação Colunas (Não utilizado)',
        param: 'order_column',
        value: 'date',
        visible: false
      }];
    }),
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/retorno-boletos/retorno-boleto-form", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    store: Ember.inject.service(),
    allowedExtensions: ['TXT'],
    bankBillReturnFile: null,
    __uploadBankBillReturnFile: function __uploadBankBillReturnFile(retornoBoleto) {
      var ajaxParams, formData, self, url;
      self = this;
      formData = new FormData();
      formData.append('file', self.get('bankBillReturnFile'));
      ajaxParams = {
        method: 'POST',
        contentType: false,
        processData: false,
        data: formData,
        headers: {
          'Client-App': 'NewEmber'
        }
      };
      url = "/retornos_boletos/" + retornoBoleto.get('id') + "/upload";
      return self.get('store').externalAjax(ajaxParams, {
        apiURL: url
      }, function (success, data, callbackParams) {
        var errors, warnings;
        errors = data.retorno_boleto.mensagem_erro;
        warnings = data.retorno_boleto.mensagem_advertencia;

        if (errors) {
          errors = errors.split('\n').filter(Boolean);
        }

        if (warnings) {
          warnings = warnings.split('\n').filter(Boolean);
        }

        self.set('mensagens_erro', errors);
        self.set('mensagens_advertencia', warnings);
        return self.get('model').reload();
      });
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.__uploadBankBillReturnFile(record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.onCancel(record);
      },
      selectBankBillReturnFile: function selectBankBillReturnFile(file, callback) {
        var description, fileName, lastModifiedDate;
        this.set('bankBillReturnFile', file);
        fileName = file.name;
        lastModifiedDate = moment(file.lastModifiedDate).format('DD/MM/YYYY');
        description = "Arquivo: <b>" + fileName + "</b><br/>Última Modificação: <b>" + lastModifiedDate + "</b>";
        return this.set('fileDescription', description);
      }
    }
  });

  _exports.default = _default;
});
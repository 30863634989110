define("api-dashboard/components/customers/customer-form", ["exports", "api-dashboard/mixins/components/customer", "api-dashboard/mixins/formatters"], function (_exports, _customer, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_formatters.default, _customer.default, (_EmberComponent$exte = {
    constants: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    partialActions: 'components/customers/form-actions',
    addReceivableModal: null,
    advanceMonthBillingModal: null,
    blockCustomerModal: null,
    cancelBankBillModal: null,
    cancelInvoiceModal: null,
    cancelInvoiceReason: null,
    contractEditModal: null,
    contractListInvalid: false,
    currentAdvanceMonthBilling: {},
    currentDetachedFee: {},
    currentEmitente: null,
    currentInvoice: null,
    currentIssueBankBill: {},
    currentReceivable: null,
    currentReIssueBankBill: null,
    currentReissueInvoice: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    },
    customerContracts: [],
    detachedFeeBankBillUrl: '',
    detachedFeesModal: null,
    hideCanceledInvoices: true,
    invoices: null,
    invoiceSearchText: '',
    issueBankBillModal: null,
    limit: 18,
    nextRecordsAvailable: true,
    nextRecordsVisible: true,
    offset: 0,
    reIssueBankBillModal: null,
    reissueInvoiceModal: null,
    showGenerateDetachedFeeButton: true,
    tabsVisible: {},
    totalRecords: 0,
    init: function init() {
      this.getInvoices();
      this.createInstallmentCredit();
      return this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self;
      self = this;
      return $.getScript('//cdnjs.cloudflare.com/ajax/libs/morris.js/0.5.1/morris.js', function (data, textStatus, jqxhr) {
        return self.loadCrmCompany();
      });
    },
    contactInfoTypes: Ember.computed(function () {
      return this.get('constants').contactInfo().typesWithLabel;
    }),
    saasPrices: Ember.computed('model', function () {
      if (this.get('model')) {
        return this.get('store').query('saas_price', {
          person_id: this.get('model.id')
        });
      }

      return [];
    })
  }, _defineProperty(_EmberComponent$exte, "customerContracts", Ember.computed('model', 'model.contracts', 'currentContract', function () {
    if (this.get('application.isAcras') && this.get('model.id')) {
      return this.get('store').query('contract', {
        customer_id: this.get('model.id')
      });
    }

    return [];
  })), _defineProperty(_EmberComponent$exte, "nextRecordsMessage", Ember.computed('remainingRecordsCount', function () {
    var limit, nextLoad, remaining;
    remaining = this.get('remainingRecordsCount');
    limit = this.get('limit');
    nextLoad = remaining > limit ? limit : remaining;

    if (remaining === 0) {
      return '';
    }

    if (remaining === 1) {
      return 'Carregar próximo registro';
    }

    return "Próximos " + nextLoad.toString() + " registros";
  })), _defineProperty(_EmberComponent$exte, "remainingRecordsCount", Ember.computed('totalRecords', 'limit', 'offset', function () {
    return this.get('totalRecords') - this.get('limit') - this.get('offset');
  })), _defineProperty(_EmberComponent$exte, "hideCancelledInvoicesObserver", Ember.observer('hideCanceledInvoices', function () {
    return this.getInvoices(false);
  })), _defineProperty(_EmberComponent$exte, "exClient", Ember.computed('crmData.crm_company.status', function () {
    return this.get('crmData.crm_company.status') === 'excliente';
  })), _defineProperty(_EmberComponent$exte, "addContactInfo", function addContactInfo() {
    return this.get('model.addNewContactInfo').call(this.get('model'));
  }), _defineProperty(_EmberComponent$exte, "addRelatedPeople", function addRelatedPeople() {
    return this.get('model.addNewRelatedPerson').call(this.get('model'));
  }), _defineProperty(_EmberComponent$exte, "createInstallmentCredit", function createInstallmentCredit() {
    return this.get('model.createInstallmentCredit').call(this.get('model'));
  }), _defineProperty(_EmberComponent$exte, "removeRelatedPerson", function removeRelatedPerson(r) {
    if (!this.get('camposDisabled') && window.confirm('Deseja realmente excluir este item?')) {
      return this.get('model.relatedPeople').removeObject(r);
    }
  }), _defineProperty(_EmberComponent$exte, "loadHistory", function loadHistory() {
    var sales;
    sales = this.get('store').query('sale', {
      customer_id: this.get('model.id')
    });
    return this.set('model.sales', sales);
  }), _defineProperty(_EmberComponent$exte, "removeContactInfo", function removeContactInfo(model) {
    if (!this.get('camposDisabled') && window.confirm('Deseja realmente excluir este item?')) {
      return alert('Registro removido com sucesso');
    }
  }), _defineProperty(_EmberComponent$exte, "addNewContract", function addNewContract() {
    var c;
    c = this.get('store').createRecord('contract', {
      customer: this.get('model')
    });
    this.set('currentContract', c);
    return this.get('contractEditModal').show();
  }), _defineProperty(_EmberComponent$exte, "editContract", function editContract(contract) {
    this.set('currentContract', contract);
    return this.get('contractEditModal').show();
  }), _defineProperty(_EmberComponent$exte, "saveCurrentContractEdit", function saveCurrentContractEdit() {
    var self;
    self = this;
    this.get('currentContract').save({
      adapterOptions: {
        subscribe: false
      }
    }).then(function (_this) {
      return function (obj) {
        self.set('currentContract', null);
        self.get('application').showAlert('Contrato salvo com sucesso!', 'success');
        return self.get('contractEditModal').hide();
      };
    }(this), function (_this) {
      return function (obj) {
        var error, field, i, len, msg, ref, results;
        ref = obj.errors;
        results = [];

        for (i = 0, len = ref.length; i < len; i++) {
          error = ref[i];
          field = error.source ? field = error.source.pointer.split('/').pop().toString() : '';
          msg = field + ' ' + (error.detail ? error.detail : error);
          results.push(self.get('application').showAlert(msg, 'error'));
        }

        return results;
      };
    }(this));
    return this.set('application.buttonSpin', false);
  }), _defineProperty(_EmberComponent$exte, "cancelCurrentContractEdit", function cancelCurrentContractEdit() {
    this.get('currentContract', null);
    return this.get('contractEditModal').hide();
  }), _defineProperty(_EmberComponent$exte, "getInvoices", function getInvoices(append) {
    var params, self;

    if (!this.get('application.currentDomain.isAcras')) {
      return;
    }

    self = this;
    params = {
      person_id: this.get('model.id'),
      order: 'data_competencia desc',
      search: this.get('invoiceSearchText'),
      hide_canceled: this.get('hideCanceledInvoices'),
      offset: this.get('offset'),
      limit: this.get('limit')
    };

    if (!append) {
      this.set('offset', 0);
      this.set('resultsAvailable', false);
    }

    self.set('searchingInvoices', true);
    return this.get('store').query('lancamento', params).then(function (result) {
      var objects;
      self.set('searchingInvoices', false);
      self.set('resultsAvailable', true);
      self.set('nextRecordsVisible', result.get('length') === self.get('limit'));
      objects = self.get('invoices');

      if (!append) {
        objects = result.toArray();
      } else {
        objects.addObjects(result.toArray());
      }

      self.set('invoices', objects);
      return self.set('totalRecords', result.get('meta.total'));
    });
  }), _defineProperty(_EmberComponent$exte, "loadCrmCompany", function loadCrmCompany() {
    var ajaxParams, self;

    if (!this.get('application.currentDomain.isAcras')) {
      return;
    }

    self = this;
    ajaxParams = {
      method: 'GET',
      data: {},
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/crm/companies/" + this.get('model.id')
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        data.data.crm_company.company_month_facts.sort(function (a, b) {
          return b.sequential_month - a.sequential_month;
        });
        return self.set('crmData', data.data.crm_company);
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
        return self.get('application').showAlert(message, toastType);
      }
    });
  }), _defineProperty(_EmberComponent$exte, "saveCrmData", function saveCrmData() {
    var ajaxParams, self;

    if (!this.get('application.currentDomain.isAcras')) {
      return;
    }

    self = this;
    ajaxParams = {
      method: 'PUT',
      data: {
        branch_activity: self.get('crmData.branch_activity'),
        commercial_briefing: self.get('crmData.commercial_briefing')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/crm/companies/" + this.get('model.id')
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        return message = 'Ramo de atividade alterada com sucesso.';
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
        return self.get('application').showAlert(message, toastType);
      }
    });
  }), _defineProperty(_EmberComponent$exte, "hasFreshdeskTicket", Ember.computed('crmData.ticket_freshdesk', function () {
    return !!this.get('crmData.ticket_freshdesk');
  })), _defineProperty(_EmberComponent$exte, "loadMoreInvoices", function loadMoreInvoices() {
    this.set('offset', this.get('offset') + this.get('limit'));
    return this.getInvoices(true);
  }), _defineProperty(_EmberComponent$exte, "cancelInvoice", function cancelInvoice() {
    var ajaxParams, self;
    this.set('cancelInvoiceSpin', true);
    self = this;

    if (this.get('cancelInvoiceReason') === null) {
      alert('Obrigatório informar motivo para não cobrar multa');
      return this.set('cancelInvoiceSpin', false);
    } else {
      ajaxParams = {
        method: "POST",
        data: {
          motive: this.get('cancelInvoiceReason')
        },
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/web_api/lancamentos/" + self.get('currentInvoice.id') + "/cancelar"
      }, function (success, textStatus, data) {
        var message, toastType;

        if (success) {
          toastType = 'success';
          message = "Fatura " + self.get('currentInvoice.descricao') + " cancelada com sucesso!";
          self.getInvoices();
          self.get('cancelInvoiceModal').hide();
        } else {
          toastType = 'error';
          message = data.jqXHR.responseJSON.msg;
        }

        self.set('cancelInvoiceSpin', false);
        return self.get('application').showAlert(message, toastType);
      });
    }
  }), _defineProperty(_EmberComponent$exte, "cancelInvoiceShow", function cancelInvoiceShow(invoice) {
    this.set('currentInvoice', invoice);
    return this.get('cancelInvoiceModal').show();
  }), _defineProperty(_EmberComponent$exte, "cancelInvoiceHide", function cancelInvoiceHide() {
    this.get('currentInvoice', null);
    return this.get('cancelInvoiceModal').hide();
  }), _defineProperty(_EmberComponent$exte, "selectEmitente", function selectEmitente(e) {
    return this.set('currentEmitente', e.record);
  }), _defineProperty(_EmberComponent$exte, "addEmitente", function addEmitente() {
    var e;

    if (this.get('currentContract') != null) {
      e = this.get('currentEmitente');
      e.set('cnpj', e.get('cnpj'));
      return this.get('currentContract').addEmitente(e);
    }
  }), _defineProperty(_EmberComponent$exte, "showDetachedFeesModal", function showDetachedFeesModal() {
    this.set('showGenerateDetachedFeeButton', true);
    this.set('currentDetachedFee', {
      descricao: '',
      valor: 0.00,
      vencimento: new Date()
    });
    return this.get('detachedFeesModal').show();
  }), _defineProperty(_EmberComponent$exte, "onSaveDetachedFee", function onSaveDetachedFee() {
    var ajaxParams, self;
    this.set('detachedFeeSpin', true);
    self = this;
    ajaxParams = {
      method: 'PUT',
      data: {
        customer_id: self.get('model.id'),
        description: self.get('currentDetachedFee.descricao'),
        amount: self.get('currentDetachedFee.valor'),
        due_date: self.get('currentDetachedFee.vencimento')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: '/web_api/saas/create_fee'
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        message = 'Taxa Avulsa gerada com sucesso!';
        self.set('detachedFeeBankBillUrl', data.jqXHR.responseJSON.boleto_url);
        self.set('showGenerateDetachedFeeButton', false);
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
      }

      self.set('detachedFeeSpin', false);
      return self.get('application').showAlert(message, toastType);
    });
  }), _defineProperty(_EmberComponent$exte, "onCancelDetachedFee", function onCancelDetachedFee(modal) {
    return modal.hide();
  }), _defineProperty(_EmberComponent$exte, "showAdvanceMonthBillingModal", function showAdvanceMonthBillingModal() {
    this.set('currentAdvanceMonthBilling.month', new Date().getMonth() + 1);
    this.set('currentAdvanceMonthBilling.year', new Date().getFullYear());
    return this.get('advanceMonthBillingModal').show();
  }), _defineProperty(_EmberComponent$exte, "onSaveAdvanceMonthBilling", function onSaveAdvanceMonthBilling() {
    var ajaxParams, self;
    this.set('advancedMonthBillingFee', true);
    self = this;
    ajaxParams = {
      method: 'PUT',
      data: {
        customer_id: this.get('model.id'),
        month: this.get('currentAdvanceMonthBilling.month'),
        year: this.get('currentAdvanceMonthBilling.year')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: '/web_api/saas/create_lancamentos'
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        message = data.jqXHR.responseJSON.msg;
        self.get('advanceMonthBillingModal').hide();
        self.getInvoices();
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
      }

      self.set('advancedMonthBillingFee', false);
      return self.get('application').showAlert(message, toastType);
    });
  }), _defineProperty(_EmberComponent$exte, "onCancelAdvanceMonthBilling", function onCancelAdvanceMonthBilling(modal) {
    return modal.hide();
  }), _defineProperty(_EmberComponent$exte, "showReissueInvoiceModal", function showReissueInvoiceModal(invoice) {
    this.set('currentReissueInvoice', {
      month: invoice.get('dataCompetencia').getMonth() + 1,
      year: invoice.get('dataCompetencia').getFullYear()
    });
    return this.get('reissueInvoiceModal').show();
  }), _defineProperty(_EmberComponent$exte, "onCancelReissueInvoice", function onCancelReissueInvoice(modal) {
    this.set('currentReissueInvoice', {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    });
    return modal.hide();
  }), _defineProperty(_EmberComponent$exte, "onSaveReissueInvoice", function onSaveReissueInvoice() {
    var ajaxParams, self;
    this.set('reissueInvoiceSpin', true);
    self = this;
    ajaxParams = {
      method: 'PUT',
      data: {
        customer_id: self.get('model.id'),
        month: self.get('currentReissueInvoice.month'),
        year: self.get('currentReissueInvoice.year')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: '/web_api/saas/recreate_receivables'
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        message = "Fatura para o mês " + (" " + self.get('currentReissueInvoice.month') + "-" + self.get('currentReissueInvoice.year') + " ") + " regerada com sucesso!";
        self.getInvoices();
        self.get('reissueInvoiceModal').hide();
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
      }

      self.set('reissueInvoiceSpin', false);
      return self.get('application').showAlert(message, toastType);
    });
  }), _defineProperty(_EmberComponent$exte, "showIssueBankBillModal", function showIssueBankBillModal(invoice) {
    this.set('currentInvoice', invoice);
    this.set('currentIssueBankBill', {
      date: new Date()
    });
    return this.get('issueBankBillModal').show();
  }), _defineProperty(_EmberComponent$exte, "onCancelIssueBankBill", function onCancelIssueBankBill(modal) {
    this.set('currentIssueBankBill', {});
    return modal.hide();
  }), _defineProperty(_EmberComponent$exte, "onSaveIssueBankBill", function onSaveIssueBankBill() {
    var ajaxParams, self;
    this.set('issueBankBillSpin', true);
    self = this;
    ajaxParams = {
      method: 'POST',
      data: {
        customer_id: self.get('model.id'),
        dueDate: self.get('currentIssueBankBill.date')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/web_api/lancamentos/" + self.get('currentInvoice.id') + "/create_boleto"
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        message = "Boleto para o lancamento " + self.get('currentInvoice.id') + " criado com sucesso!";
        self.get('issueBankBillModal').hide();
        self.getInvoices();
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
      }

      self.set('issueBankBillSpin', false);
      return self.get('application').showAlert(message, toastType);
    });
  }), _defineProperty(_EmberComponent$exte, "showCancelBankBillModal", function showCancelBankBillModal(bankBill) {
    this.set('currentCancelBankBill', bankBill);
    return this.get('cancelBankBillModal').show();
  }), _defineProperty(_EmberComponent$exte, "onCancelBankBill", function onCancelBankBill() {
    var ajaxParams, self;
    this.set('cancelBankBillSpin', true);
    self = this;

    if (!this.get('cancelBankBillReason')) {
      alert('Preencher o motivo do Cancelamento!');
      self.set('cancelBankBillSpin', true);
      return;
    }

    ajaxParams = {
      method: "POST",
      data: {
        customer_id: self.get('model.id'),
        reason: self.get('cancelBankBillReason')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/web_api/boletos/" + this.get('currentCancelBankBill.id') + "/cancel"
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        message = "Boleto " + self.get('currentCancelBankBill.id') + " cancelado com sucesso!";
        self.get('cancelBankBillModal').hide();
        self.getInvoices();
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
      }

      self.set('cancelBankBillSpin', false);
      return self.get('application').showAlert(message, toastType);
    });
  }), _defineProperty(_EmberComponent$exte, "onIssueNFSe", function onIssueNFSe(invoice) {
    var ajaxParams, confirmation_text, invoice_date, self;
    invoice_date = this.formatDate(invoice.get('dataCompetencia'), {
      format: 'MMMM YYYY'
    });
    confirmation_text = "Confirma a emissão da NFSE Para o lancamento " + invoice.get('id') + " " + (" [$ " + invoice.get('valor') + "] do mês " + invoice_date + "?");

    if (window.confirm(confirmation_text)) {
      self = this;
      ajaxParams = {
        method: 'POST',
        data: {
          customer_id: this.get('model.id')
        },
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/web_api/lancamentos/" + invoice.get('id') + "/create_nfse"
      }, function (success, textStatus, data) {
        var message, toastType;

        if (success) {
          toastType = 'success';
          message = "Nota para o Lançamento " + invoice.get('id') + " gerada com sucesso!";
          self.get('cancelBankBillModal').hide();
          self.getInvoices();
        } else {
          toastType = 'error';
          message = data.jqXHR.responseJSON.msg;
        }

        return self.get('application').showAlert(message, toastType);
      });
    }
  }), _defineProperty(_EmberComponent$exte, "showAddReceivableModal", function showAddReceivableModal() {
    var r;
    r = this.get('store').createRecord('receivable', {});
    r.set('customer', this.get('model'));
    r.set('status', 'open');
    r.set('month', new Date().getMonth() + 1);
    r.set('year', new Date().getFullYear());
    this.set('currentReceivable', r);
    return this.get('addReceivableModal').show();
  }), _defineProperty(_EmberComponent$exte, "onSaveReceivable", function onSaveReceivable() {
    var self;
    this.set('receivableSpin', true);
    self = this;
    return this.get('currentReceivable').save().then(function (_this) {
      return function (obj) {
        self.get('application').showAlert('Recebível cadastrado com sucesso!', 'success');
        self.get('addReceivableModal').hide();
        return self.set('receivableSpin', false);
      };
    }(this), function (_this) {
      return function (obj) {
        var e, field, i, len, msg, ref;

        if (obj.errors) {
          ref = obj.errors;

          for (i = 0, len = ref.length; i < len; i++) {
            e = ref[i];
            field = e.source.pointer.split('/').pop().toString();
            msg = field(+' ' + e.detail);
            self.get('application').showAlert(msg, 'error');
          }

          return;
        }

        self.set('receivableSpin', false);
        return self.get('application').showAlert('Ocorreu um erro ao salvar o recebível.', 'error');
      };
    }(this));
  }), _defineProperty(_EmberComponent$exte, "onCancelSaveReceivable", function onCancelSaveReceivable(modal) {
    this.set('currentReceivable', {});
    return modal.hide();
  }), _defineProperty(_EmberComponent$exte, "reIssueBankBill", function reIssueBankBill(bankBill) {
    var reIssueBankBill;
    reIssueBankBill = {
      id: bankBill.get('id'),
      dueDate: new Date(),
      applyFine: false,
      discount: 0.0,
      reason: ''
    };
    this.set('currentReIssueBankBill', reIssueBankBill);
    return this.get('reIssueBankBillModal').show();
  }), _defineProperty(_EmberComponent$exte, "onSaveReIssueBankBill", function onSaveReIssueBankBill() {
    var ajaxParams, self;
    this.set('reissueBankBillSpin', true);
    self = this;

    if (!self.get('currentReIssueBankBill.reason')) {
      alert('Preencher o motivo da reemissão do boleto!');
      self.set('reissueBankBillSpin', false);
      return;
    }

    ajaxParams = {
      method: 'POST',
      data: {
        new_due_date: self.get('currentReIssueBankBill.dueDate'),
        apply_fine: self.get('currentReIssueBankBill.applyeFine'),
        discount_percent: self.get('currentReIssueBankBill.discount'),
        boleto_id: self.get('currentReIssueBankBill.id'),
        motive: self.get('currentReIssueBankBill.reason')
      },
      global: false
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/web_api/boletos/" + self.get('currentReIssueBankBill.id') + "/recreate"
    }, function (success, textStatus, data) {
      var message, toastType;

      if (success) {
        toastType = 'success';
        message = "Boleto reemitido com sucesso!";
        self.get('reIssueBankBillModal').hide();
        self.getInvoices();
      } else {
        toastType = 'error';
        message = data.jqXHR.responseJSON.msg;
      }

      self.set('reissueBankBillSpin', false);
      return self.get('application').showAlert(message, toastType);
    });
  }), _defineProperty(_EmberComponent$exte, "onCancelReIssueBankBill", function onCancelReIssueBankBill(modal) {
    this.set('currentReIssueBankBill', {});
    return modal.hide();
  }), _defineProperty(_EmberComponent$exte, "fillAddressFields", function fillAddressFields(params) {
    var codigoMunicipio, self;

    if (params['valido']) {
      self = this;
      self.set('fetchingCep', true);
      codigoMunicipio = params['endereco']['ibge'];
      return this.get('store').queryRecord('municipio', {
        codigo_municipio: codigoMunicipio
      }).then(function (obj) {
        var bairro, cidade, logradouro, pais;
        cidade = obj;
        logradouro = params['endereco']['logradouro'];
        bairro = params['endereco']['bairro'];
        pais = params['endereco']['país'];

        if (params['addressType'] === 'default') {
          self.set('model.defaultAddress.municipio', cidade);
          self.set('model.defaultAddress.logradouro', logradouro);
          self.set('model.defaultAddress.bairro', bairro);

          if (!!pais) {
            self.set('model.defaultAddress.pais', pais);
          }

          self.set('cidadeDefaultDisabled', !!cidade);
          self.set('logradouroDefaultDisabled', !!logradouro);
          self.set('bairroDefaultDisabled', !!bairro);
          return self.set('fetchingCep', false);
        } else if (params['addressType'] === 'billing') {
          self.set('model.billingAddress.municipio', cidade);
          self.set('model.billingAddress.logradouro', logradouro);
          self.set('model.billingAddress.bairro', bairro);

          if (!!pais) {
            self.set('model.billingAddress.pais', pais);
          }

          self.set('cidadeBillingDisabled', !!cidade);
          self.set('logradouroBillingDisabled', !!logradouro);
          self.set('bairroBillingDisabled', !!bairro);
          return self.set('fetchingCep', false);
        } else if (params['addressType'] === 'shipping') {
          self.set('model.shippingAddress.municipio', cidade);
          self.set('model.shippingAddress.logradouro', logradouro);
          self.set('model.shippingAddress.bairro', bairro);

          if (!!pais) {
            self.set('model.shippingAddress.pais', pais);
          }

          self.set('cidadeShippingDisabled', !!cidade);
          self.set('logradouroShippingDisabled', !!logradouro);
          self.set('bairroShippingDisabled', !!bairro);
          return self.set('fetchingCep', false);
        }
      });
    }
  }), _defineProperty(_EmberComponent$exte, "onSubmit", function onSubmit(customer) {
    this.saveCrmData();
    return this.sendAction('afterSave', customer);
  }), _defineProperty(_EmberComponent$exte, "beforeValidate", function beforeValidate() {
    var contactInfos;
    contactInfos = this.get('model.contactInfos');
    contactInfos.forEach(function (c) {
      if (!!c.get('contactType') && (c.get('value') === null || c.get('value') === void 0)) {
        return c.set('value', '');
      }
    });
    contactInfos = contactInfos.filter(function (c) {
      return !!c.get('contactType') && (c.get('value') !== null || c.get('value') !== void 0);
    });
    return this.set('model.contactInfos', contactInfos);
  }), _defineProperty(_EmberComponent$exte, "actions", {
    onCancel: function onCancel(customer) {
      if (customer == null) {
        customer = null;
      }

      return this.sendAction('onCancel', customer);
    },
    addContact: function addContact() {
      return this.addContactInfo();
    },
    addRelatedPerson: function addRelatedPerson() {
      return this.addRelatedPeople();
    },
    loadContracts: function loadContracts() {
      if (this.get('application.isAcras') && this.get('model.id')) {
        this.set('customerContracts', this.get('store').find('contract', {
          customer_id: this.get('model.id')
        }));
        return this.set('contractListInvalid', false);
      }
    },
    tabClick: function tabClick(tabId) {
      var k, tabsVisible;
      tabsVisible = {};

      for (k in this.get('tabsVisible')) {
        tabsVisible[k] = false;
      }

      tabsVisible[tabId] = true;
      return this.set('tabsVisible', tabsVisible);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});
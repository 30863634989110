define("api-dashboard/components/waybills/waybill-form", ["exports", "api-dashboard/mixins/formatters", "api-dashboard/mixins/keyboard-utils"], function (_exports, _formatters, _keyboardUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, _keyboardUtils.default, {
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    router: Ember.inject.service(),
    isSavingWaybill: false,
    finalizingWaybill: false,
    item: null,
    endItemInsertionEnabled: true,
    errors: Ember.Object.create(),
    productFoundByCode: false,
    productDuplicated: false,
    showItemEditForm: false,
    searchingProduct: false,
    costObserver: Ember.observer('item.cost', function () {
      var cost, markup, newValue, product, productCost;
      cost = new Big(this.get('item.cost') || 0);

      if (Ember.isEmpty(cost) || cost.eq(0)) {
        return;
      }

      product = this.get('item.product');

      if (product != null) {
        productCost = product.get('averageCost');

        if (!Ember.isEmpty(productCost) && cost.eq(productCost)) {
          return;
        }
      }

      if (!Ember.isEmpty(this.get('item.price'))) {
        return;
      }

      markup = this.get('markup');
      newValue = cost * (1 + markup / 100);

      newValue = function () {
        switch (this.get('roundOption')) {
          case 'NOROUND':
            return newValue;

          case 'ROUNDNEARESTINT':
            return Math.round(newValue);

          case 'ROUNDUP':
            return Math.ceil(newValue);

          case 'ROUNDDOWN':
            return Math.floor(newValue);
        }
      }.call(this);

      return this.set('item.price', Big(newValue));
    }),
    init: function init() {
      this.set('searchingToggles', true);
      this.set('searchingStores', true);
      return this._super.apply(this, arguments);
    },
    validateHeader: function validateHeader() {
      var isValid;
      isValid = true;

      if (!this.get('model.entryDate')) {
        this.get('application').showAlert('A Data de Entrada deve ser preenchida!', 'error');
        isValid = false;
      }

      if (!this.get('model.fStore.id')) {
        this.get('application').showAlert('A Loja de Destino deve ser preenchida!', 'error');
        isValid = false;
      }

      if (!this.get('model.supplier.id')) {
        this.get('application').showAlert('Caso o Fornecedor do Romaneio não seja preenchido, será ' + 'necessário preencher o Fornecedor em todos os Itens!', 'warning', 40000);
      }

      return isValid;
    },
    validateWaybill: function validateWaybill() {
      var everyItemHasSupplier, isValid;
      isValid = true;
      everyItemHasSupplier = this.get('model.items').every(function (i) {
        return !!i.get('supplier.id');
      });

      if (!this.get('model.supplier.id') && !everyItemHasSupplier) {
        this.get('application').showAlert(' <p>O Fornecedor deve ser preenchido para o Romaneio\n<strong>ou</strong> para todos os Itens</p>', 'error', 10000);
        isValid = false;
      }

      return isValid;
    },
    removeUnsavedItems: function removeUnsavedItems() {
      var itemsUnsaved;
      itemsUnsaved = this.get('model.items').filter(function (i) {
        return !i.get('id');
      });
      return itemsUnsaved.forEach(function (i) {
        return i.destroyRecord();
      });
    },
    beforeValidate: function beforeValidate() {
      var everyItemHasSupplier, itemsWithoutSupplier, self;
      self = this;
      everyItemHasSupplier = this.get('model.items').every(function (i) {
        return !!i.get('supplier.id');
      });

      if (this.get('model.supplier.id') && !everyItemHasSupplier) {
        itemsWithoutSupplier = this.get('model.items').filter(function (i) {
          return !i.get('supplier.id');
        });
        return itemsWithoutSupplier.forEach(function (i) {
          i.set('supplier', self.get('model.supplier'));
          return i.save().then(function (obj) {}, function (error) {});
        });
      }
    },
    keyDown: function keyDown(e) {
      return this.keyDown_EnterAsTab(e, 'div.painelFocus__container');
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      createWaybill: function createWaybill() {
        var m, self;
        self = this;

        if (this.validateHeader()) {
          m = this.set('isSavingWaybill', true);
          m = this.get('model');
          return m.save().then(function (w) {
            return self.get('router').transitionTo('waybills/edit', w);
          })["catch"](function (error) {
            var errorMessages;
            errorMessages = [];
            error.errors.forEach(function (e) {
              var field, message;
              field = e.source.pointer.split('/').pop();
              message = e.detail;
              return errorMessages.addObject("Campo: " + field + ": " + message);
            });

            if (errorMessages.length > 0) {
              return self.get('application').showAlert(errorMessages.join('<br/>'));
            }
          });
        }
      },
      addNewWaybillItem: function addNewWaybillItem() {
        var item, self;
        self = this;
        item = this.get('store').createRecord('waybill-item', {
          waybill: this.get('model'),
          collection: this.get('model.collection')
        });

        if (this.get('model.supplier')) {
          item.set('supplier', this.get('model.supplier'));
        }

        this.set('item', item);
        this.set('item.isEditing', true);
        this.get('application.currentDomain.visibleStores').forEach(function (st) {
          var iq;
          iq = self.get('store').createRecord('waybill-item-quantity', {
            fStore: st,
            waybillItem: self.get('item')
          });
          return self.get('item.quantities').addObject(iq);
        });
        return this.set('showItemEditForm', true);
      },
      editItem: function editItem(item, oldItem) {
        var self;

        if (oldItem && oldItem.get('isNew') && ['code', 'price', 'codigoNcm', 'barcode', 'description', 'price', 'cost', 'department.id', 'productType.id'].some(function (field) {
          return oldItem.get(field) && oldItem.get(field) !== 'NaN';
        })) {
          return alert('Você deverá salvar ou descartar o item atual antes de editar outro item');
        }

        self = this;
        this.set('showItemEditForm', true);
        this.set('productExists', true);

        if (oldItem) {
          Ember.set(oldItem, 'isEditing', false);
        }

        if (item.get('isNew')) {
          self.set('item', item);
        } else {
          this.set('searchingProduct', true);
          this.get('store').findRecord('waybill-item', item.get('id'), {
            reload: true
          }).then(function (obj) {
            obj.set('isEditing', true);
            self.set('item', obj);
            return self.set('searchingProduct', false);
          });
        }

        return this.get('item');
      },
      finalizeWaybill: function finalizeWaybill() {
        var self;
        self = this;

        if (confirm('Tem certeza que deseja realizar a entrada dos itens?')) {
          this.beforeValidate();

          if (this.validateWaybill()) {
            this.removeUnsavedItems();
            self.set('model.status', 'COMPLETED');
            return self.get('model').save().then(function (obj) {
              return self.get('application').showAlert('Entrada de produtos registrada com sucesso!', 'success');
            }, function (error) {
              return self.get('application').showAlert('Ocorreu um erro ao salvar a entrada de produtos.' + ' Seus dados *não* foram salvos. Por favor recarregue a página e tente novamente.', 'error');
            });
          }
        }
      },
      deleteItem: function deleteItem(item) {
        var self;
        self = this;

        if (confirm("Tem certeza que deseja excluir o item " + item.get('code') + "?")) {
          item.destroyRecord().then(function (obj) {
            self.set('item', null);
            self.set('showItemEditForm', false);
            return self.get('application').showAlert('Item excluído com sucesso!', 'success');
          }, function (obj) {
            return self.get('application').showAlert('Erro ao excluir o item!', 'error');
          });
          return this.get('model').save();
        }
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();

        if (!this.get('model.id')) {
          this.get('store').unloadRecord(this.get('model'));
        }

        return this.sendAction('onCancel', null);
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/card-company-configs/card-company-config-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      return this._super.apply(this, arguments);
    },
    cardCompanies: Ember.computed(function () {
      return this.get('store').findAll('card-company');
    }),
    enabledCardBrand: Ember.computed('enabledCardBrands', 'fStore', function (key, value) {
      var ecb, enabledCardBrands, fStore;

      if (arguments.length > 1) {
        return this.set('ecb', value);
      } else {
        ecb = this.get('ecb');

        if (ecb != null) {
          return ecb;
        }

        enabledCardBrands = this.get('enabledCardBrands');
        fStore = this.get('fStore');

        if (!(enabledCardBrands != null && fStore != null)) {
          return false;
        }

        ecb = enabledCardBrands.find('fStore', fStore);

        if (ecb != null) {
          this.set('ecb', ecb);
        }

        return ecb;
      }
    }),
    acceptsInstallmentCreditObserver: function () {
      if (this.get('model.acceptsInstallmentCredit')) {
        return this.set('model.acceptsRevolvingCredit', true);
      }
    }.observes('model.acceptsInstallmentCredit').on('init'),
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      }
    }
  });

  _exports.default = _default;
});
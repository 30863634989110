define("api-dashboard/router", ["exports", "api-dashboard/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router;
  Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('labs');
    this.route('entrar');
    this.route('selecao-domain');
    this.route('pagina-nao-encontrada', {
      path: '/*path'
    });
    this.route('busca-referencia');
    this.route('cadastro-empresa');
    this.route('empresas/index', {
      path: '/empresas'
    });
    this.route('nova-empresa');
    this.route('empresas/new');
    this.route('empresas.editar', {
      path: '/empresa/:id/editar'
    });
    this.route('empresas/edit', {
      path: '/empresas/:id/edit'
    });
    this.route('upload-certificado', {
      path: '/empresas/:id/enviar-certificado'
    });
    this.route('empresas/upload-certificado', {
      path: '/empresas/:id/upload-certificado'
    });
    this.route('departments/index', {
      path: '/departments'
    });
    this.route('departments/new');
    this.route('departments/edit', {
      path: '/departments/:id/edit'
    });
    this.route('product-types/index', {
      path: '/product-types'
    });
    this.route('product-types/new');
    this.route('product-types/edit', {
      path: '/product-types/:id/edit'
    });
    this.route('sale_types/index', {
      path: '/sale_types'
    });
    this.route('sale_types/new');
    this.route('sale_types/edit', {
      path: '/sale_types/:id/edit'
    });
    this.route('collections/index', {
      path: '/collections'
    });
    this.route('collections/new');
    this.route('collections/edit', {
      path: '/collections/:id/edit'
    });
    this.route('suppliers/index', {
      path: '/suppliers'
    });
    this.route('suppliers/new');
    this.route('suppliers/edit', {
      path: 'suppliers/:id/edit'
    });
    this.route('supplier-categories/index', {
      path: '/supplier-categories'
    });
    this.route('supplier-categories/new');
    this.route('supplier-categories/edit', {
      path: '/supplier-categories/:id/edit'
    });
    this.route('notas-fiscais/index', {
      path: '/notas-fiscais/'
    });
    this.route('notas-fiscais/edit', {
      path: '/notas-fiscais/:id/edit'
    });
    this.route('notas-fiscais/show', {
      path: '/notas-fiscais/:id/show'
    });
    this.route('notas-fiscais/new');
    this.route('itens/new');
    this.route('itens/edit', {
      path: '/itens/:id/edit'
    });
    this.route('documentos-importacao/new');
    this.route('documentos-importacao/edit', {
      path: '/documentos-importacao/:id/edit'
    });
    this.route('customers/index', {
      path: '/customers'
    });
    this.route('customers/new');
    this.route('customers/edit', {
      path: '/customers/:id/edit'
    });
    this.route('products/index', {
      path: '/products'
    });
    this.route('products/new');
    this.route('products/edit', {
      path: 'products/:id/edit'
    });
    this.route('products/inventories', {
      path: '/products/:id/inventories'
    });
    this.route('products/inventories-balance', {
      path: '/products/:id/inventories-balance'
    });
    this.route('invoices/index', {
      path: '/invoices'
    });
    this.route('invoices/edit', {
      path: '/invoices/:id/edit'
    });
    this.route('invoices/show', {
      path: '/invoices/:id/show'
    });
    this.route('invoices/dispute/new');
    this.route('invoices/config/company/edit', {
      path: '/invoices/config/company/:id/edit'
    });
    this.route("invoices/saas-contracts/index", {
      path: "invoices/saas-contracts"
    });
    this.route('stores-dashboard/index', {
      path: '/stores-dashboard'
    });
    this.route('system-config/edit', {
      path: '/system-config'
    });
    this.route('stores-config/index', {
      path: '/stores-config'
    });
    this.route('stores-config/edit', {
      path: '/stores-config/:id'
    });
    this.route('emitentes-config/index', {
      path: '/emitentes-config'
    });
    this.route('emitentes-config/edit', {
      path: '/emitentes-config/:id'
    });
    this.route('pos-reports/edit', {
      path: '/pos-reports'
    });
    this.route('inscricao-estadual/index', {
      path: '/inscricao-estadual'
    });
    this.route('inscricao-estadual/new');
    this.route('inscricao-estadual/edit', {
      path: '/inscricao-estadual/:id/edit'
    });
    this.route('conta-bancaria/index', {
      path: '/conta-bancaria'
    });
    this.route('conta-bancaria/new');
    this.route('conta-bancaria/edit', {
      path: '/conta-bancaria/:id/edit'
    });
    this.route('reports/index', {
      path: '/reports'
    });
    this.route('cancel/new', {
      path: '/cancel'
    });
    this.route("cash-drawers/index", {
      path: "/cash-drawers"
    });
    this.route("cash-drawers/new");
    this.route("cash-drawers/edit", {
      path: "/cash-drawers/:id/edit"
    });
    this.route("checks/index", {
      path: "/checks"
    });
    this.route("checks/new");
    this.route("checks/edit", {
      path: "/checks/:id/edit"
    });
    this.route("sale-types/index", {
      path: "/sale-types"
    });
    this.route("sale-types/new");
    this.route("sale-types/edit", {
      path: "/sale-types/:id/edit"
    });
    this.route("modelos-lancamentos/index", {
      path: "/modelos-lancamentos"
    });
    this.route("modelos-lancamentos/new");
    this.route("modelos-lancamentos/edit", {
      path: "/modelos-lancamentos/:id/edit"
    });
    this.route("gift-coupons/index", {
      path: "/gift-coupons"
    });
    this.route("gift-coupons/new");
    this.route("gift-coupons/edit", {
      path: "/gift-coupons/:id/edit"
    });
    this.route("users/index", {
      path: "/users"
    });
    this.route("users/new");
    this.route("users/edit", {
      path: "/users/:id/edit"
    });
    this.route("pos-users/index", {
      path: "/pos-users"
    });
    this.route("pos-users/new");
    this.route("pos-users/edit", {
      path: "/pos-users/:id/edit"
    });
    this.route("promotions/index", {
      path: "/promotions"
    });
    this.route("promotions/new");
    this.route("promotions/edit", {
      path: "/promotions/:id/edit"
    });
    this.route("dimensions/index", {
      path: "/dimensions"
    });
    this.route("dimensions/new");
    this.route("dimensions/edit", {
      path: "/dimensions/:id/edit"
    });
    this.route("cost-centers/index", {
      path: "/cost-centers"
    });
    this.route("cost-centers/new");
    this.route("cost-centers/edit", {
      path: "/cost-centers/:id/edit"
    });
    this.route("waybills/index", {
      path: "/waybills"
    });
    this.route("waybills/new");
    this.route("waybills/edit", {
      path: "/waybills/:id/edit"
    });
    this.route("manual-input-output/new", {
      path: "/manual-input-output"
    });
    this.route("card-company-configs/index", {
      path: "/card-company-configs"
    });
    this.route("card-company-configs/new");
    this.route("card-company-configs/edit", {
      path: "/card-company-configs/:id/edit"
    });
    this.route("return-to-suppliers/index", {
      path: "/return-to-suppliers"
    });
    this.route("return-to-suppliers/new");
    this.route("return-to-suppliers/edit", {
      path: "/return-to-suppliers/:id/edit"
    });
    this.route("sales/index", {
      path: "/sales"
    });
    this.route("sales/new");
    this.route("sales/edit", {
      path: "/sales/:id/edit"
    });
    this.route("transfers/index", {
      path: "/transfers"
    });
    this.route("transfers/new");
    this.route("transfers/edit", {
      path: "/transfers/:id/edit"
    });
    this.route("notas-fiscais-servico/index", {
      path: "/notas-fiscais-servico"
    });
    this.route("notas-fiscais-servico/new");
    this.route("notas-fiscais-servico/edit", {
      path: "/notas-fiscais-servico/:id/edit"
    });
    this.route("modelos-notas-fiscais/index", {
      path: "/modelos-notas-fiscais"
    });
    this.route("modelos-notas-fiscais/new");
    this.route("modelos-notas-fiscais/edit", {
      path: "/modelos-notas-fiscais/:id/edit"
    });
    this.route("transportadores/index", {
      path: "/transportadores"
    });
    this.route("transportadores/new");
    this.route("transportadores/edit", {
      path: "/transportadores/:id/edit"
    });
    this.route("download-files/index", {
      path: "/download-files"
    });
    this.route("manual-entries/new");
    this.route("kits/index", {
      path: "/kits"
    });
    this.route("kits/new");
    this.route("kits/edit", {
      path: "/kits/:id/edit"
    });
    this.route("planos-conta/index", {
      path: "/planos-conta"
    });
    this.route("lancamentos/index", {
      path: "/lancamentos"
    });
    this.route("lancamentos/new");
    this.route("lancamentos/edit", {
      path: "/lancamentos/:id/edit"
    });
    this.route("retorno-boletos/index", {
      path: "/retorno-boletos"
    });
    this.route("retorno-boletos/new");
    this.route("retorno-boletos/edit", {
      path: "/retorno-boletos/:id/edit"
    });
    this.route("saas-prices/index", {
      path: "/saas-prices"
    });
    this.route("saas-prices/new");
    return this.route("saas-prices/edit", {
      path: "/saas-prices/:id/edit"
    });
  });
  var _default = Router;
  _exports.default = _default;
});
define("api-dashboard/components/collections/collection-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'collection',
    title: 'Coleção',
    titlePlural: 'Coleções',
    layoutColumns: 8,
    layoutNoRecords: 'collections/none-registered',
    newRoute: 'collections/new',
    editRoute: 'collections/edit',
    partialAfterTitle: null,
    customSearchButton: false,
    refinementsSearch: false,
    showRefinedSearch: false,
    columns: [{
      field: 'name',
      label: 'Nome'
    }],
    searchParams: [],
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N3FqU+X7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"mostrarTitulo\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[8],[0,\"Dashboard\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"application\",\"currentDomain\",\"isPainelApiNfe\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[8],[0,\"\\n\"],[0,\"\\t  \"],[1,[28,\"busca-referencias/grid-referencias\",null,[[\"carregarUltimasEmissoes\",\"class\"],[true,\"fadeInUp\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"application\",\"currentDomain\",\"isLojas\"]]],null,{\"statements\":[[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[1,[22,\"snippets/last-15-day-sale\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"application\",\"currentDomain\",\"isNfe\"]]],null,{\"statements\":[[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[1,[22,\"snippets/last-nfe\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/index.hbs"
    }
  });

  _exports.default = _default;
});
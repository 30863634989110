define("api-dashboard/components/departments/department-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'department',
    title: 'Departamento',
    titlePlural: 'Departamentos',
    layoutColumns: 6,
    layoutNoRecords: 'departments/none-registered',
    newRoute: 'departments/new',
    editRoute: 'departments/edit',
    partialAfterTitle: null,
    customSearchButton: false,
    refinementsSearch: false,
    showRefinedSearch: false,
    columns: [{
      field: 'name',
      label: 'Nome'
    }],
    searchParams: [],
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/cash-drawers/cash-drawers-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/mixins/utils"], function (_exports, _omnisearchIndex, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_utils.default, {
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    store_pos: [],
    model: null,
    searchModelName: 'cash-drawer',
    title: 'Caixa PDV',
    titlePlural: 'Caixas dos PDVs',
    newRoute: null,
    editRoute: 'cash-drawers/edit',
    partialActions: 'cash-drawers/actions',
    partialAfterSearch: 'cash-drawers/after-search',
    customSearchButton: true,
    showFilters: true,
    showRefinedSearch: true,
    allowDelete: false,
    onlyShowItem: true,
    columns: [{
      field: 'openingTime',
      label: 'Abertura',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY HH:mm:SS'
      }
    }, {
      field: 'closureTime',
      label: 'Fechamento',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY HH:mm:SS'
      }
    }, {
      field: 'posUser.name',
      label: 'Responsável'
    }, {
      field: 'fStore.name',
      label: 'Loja'
    }, {
      field: 'pos.number',
      label: 'PDV'
    }],
    searchParams: Ember.computed(function () {
      return [{
        label: 'Loja',
        field: 'id',
        param: 'store_id',
        component: 'inputs/input-select',
        displayField: 'name',
        dataSource: this.get('globalLookups.stores'),
        valueField: 'id',
        value: null,
        onChange: this.get('onSelectStoreFilter').bind(this)
      }, {
        label: 'PDV',
        field: 'pos_id',
        param: 'pos_id',
        component: 'inputs/input-select',
        displayField: 'number',
        valueField: 'id',
        prompt: 'Selecione uma Loja'
      }, {
        label: 'Período De',
        field: 'createdAt',
        param: 'date_from',
        component: 'inputs/input-date'
      }, {
        label: 'Período Até',
        field: 'createdAt',
        param: 'date_to',
        component: 'inputs/input-date'
      }, {
        label: 'Número do Movimento',
        field: 'pos_entry_number',
        param: 'pos_entry_number'
      }, {
        label: 'Somente Caixas Fechados',
        field: 'only_closed',
        param: 'only_closed',
        component: 'inputs/input-toggle',
        value: true,
        cols: 3
      }, {
        label: 'Somente Caixas Sem Fechamento',
        field: 'only_not_closed',
        param: 'only_not_closed',
        component: 'inputs/input-toggle',
        value: false,
        cols: 3
      }, {
        label: 'Ordenação Registros (Não mostrar ao usuário)',
        param: 'order_ascending',
        value: true,
        visible: false
      }, {
        label: 'Ordenação Colunas (Não utilizado)',
        param: 'order_column',
        value: '',
        visible: false
      }];
    }),
    init: function init(args) {
      this._super(args);

      return this.get('userSession');
    },
    onSelectStoreFilter: function onSelectStoreFilter(previousValue, currentValue) {
      var pos_param, self;
      self = this;
      pos_param = this.get('searchParams')[1];
      return this.get('store').query('pos', {
        store_id: currentValue
      }).then(function (obj) {
        Ember.set(pos_param, 'dataSource', obj);
        return self.get('searchParams').splice(1, 1, pos_param);
      });
    },
    actions: {
      downloadSingleCashDrawerCSV: function downloadSingleCashDrawerCSV(cashDrawer) {
        var self;

        if (cashDrawer) {
          self = this;
          this.set('userSession.buttonSpin', true);
          this.downloadURL(cashDrawer.get('csvUrl'), "caixa-" + cashDrawer.get('id') + ".csv", function () {
            return self.set('userSession.buttonSpin', false);
          });
          return this.set('userSession.buttonSpin', false);
        }
      },
      downloadAllCashDrawersCSV: function downloadAllCashDrawersCSV() {
        var params, self;
        self = this;
        this.set('userSession.buttonSpin', true);

        if (this.get('searchParams')) {
          params = [];
          this.get('searchParams').forEach(function (p) {
            if (p.value !== null && p.value !== void 0) {
              return params.push(p.param + "=" + p.value);
            }
          });
          params = params.join('&');
        }

        return this.downloadURL('/cash_drawers.csv?' + params, 'caixas.csv', function () {
          return self.set('userSession.buttonSpin', true);
        });
      }
    }
  });

  _exports.default = _default;
});
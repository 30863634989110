define("api-dashboard/templates/components/product-types/product-type-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IZx057yj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Novo\"]],\"parameters\":[]},null],[0,\" Tipo de Produto\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"base/base-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],\"onSubmit\",\"onCancel\"]],{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"cols\",\"val\",\"errors\"],[\"Nome\",\"6\",[24,[\"model\",\"name\"]],[24,[\"model\",\"errors\",\"name\"]]]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"cols\",\"val\",\"errors\"],[\"Código NCM\",\"6\",[24,[\"model\",\"codigoNcm\"]],[24,[\"model\",\"errors\",\"codigoNcm\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/product-types/product-type-form.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/card-company-configs/card-company-config-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'card-company-config',
    title: 'Operadora de Cartões',
    titlePlural: 'Operadoras de Cartão',
    newRoute: 'card-company-configs/new',
    editRoute: 'card-company-configs/edit',
    allowDelete: false,
    partialStats: null,
    columns: [{
      field: 'name',
      label: 'Nome'
    }],
    searchParams: [],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/waybills/waybill-item-dimensions", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, {
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    item: null,
    dimensions1: Ember.computed(function () {
      return this.get('application.currentDomain.dimensions');
    }),
    dimension1Observer: Ember.observer('item.dimension1.id', function () {
      var self;

      if (this.get('item.product.id')) {
        return;
      }

      self = this;
      return Ember.run.once(this, function () {
        var dimensions;

        self.__clearDimensionValues(1);

        if (!self.get('item.dimension1.id')) {
          self.set('item.dimension2', null);

          self.__clearDimensionValues(2);
        } else {
          if (self.get('item.dimension1.id') === self.get('item.dimension2.id')) {
            self.set('item.dimension2', null);
          }

          if (self.get('item.dimension1.id') === self.get('item.dimension3.id')) {
            self.set('item.dimension3', null);
          }
        }

        dimensions = self.get('dimensions1').filter(function (d) {
          return d.id !== self.get('item.dimension1.id');
        });
        self.set('dimensions2', dimensions);
        return dimensions.forEach(function (d) {
          if (d.id === self.get('item.dimension2.id')) {
            self.set('item.dimension2', d);
          }

          if (d.id === self.get('item.dimension3.id')) {
            return self.set('item.dimension3', d);
          }
        });
      });
    }),
    dimension2Observer: Ember.observer('item.dimension2.id', function () {
      var self;

      if (this.get('item.product.id')) {
        return;
      }

      self = this;
      return Ember.run.once(this, function () {
        var dimensions;

        self.__clearDimensionValues(2);

        if (!self.get('item.dimension2.id')) {
          self.set('item.dimension3', null);

          self.__clearDimensionValues(3);
        } else {
          if (self.get('item.dimension2.id') === self.get('item.dimension3.id')) {
            self.set('item.dimension3', null);
          }
        }

        dimensions = self.get('dimensions1').filter(function (d) {
          return d.id !== self.get('item.dimension1.id') && d.id !== self.get('item.dimension2.id');
        });
        self.set('dimensions3', dimensions);
        return dimensions.forEach(function (d) {
          if (d.id === self.get('item.dimension3.id')) {
            return self.set('item.dimension3', d);
          }
        });
      });
    }),
    dimension3Observer: Ember.observer('item.dimension3.id', function () {
      var self;

      if (this.get('item.product.id')) {
        return;
      }

      self = this;
      return Ember.run.once(this, function () {
        return self.__clearDimensionValues(3);
      });
    }),
    enableSearchDimensions1: Ember.computed('item.dimension1', function () {
      return this.get('item.dimension1.values.length') > 50;
    }),
    enableSearchDimensions2: Ember.computed('item.dimension2', function () {
      return this.get('item.dimension2.values.length') > 50;
    }),
    enableSearchDimensions3: Ember.computed('item.dimension3', function () {
      return this.get('item.dimension3.values.length') > 50;
    }),
    __clearDimensionValues: function __clearDimensionValues(dimensionNumber) {
      return this.get('item.items').forEach(function (i) {
        return i.set("dimension" + dimensionNumber + "Value", null);
      });
    },
    addNewDimension: function addNewDimension() {
      var newItem;
      newItem = this.get('item.items').createRecord({
        supplier: this.get('item.supplier'),
        collection: this.get('item.collection'),
        waybill: this.get('model'),
        mainWaybillItem: this.get('item')
      });
      this.get('application.currentDomain.visibleStores').forEach(function (st) {
        return newItem.get('quantities').createRecord({
          fStore: st
        });
      });
      return newItem;
    },
    actions: {
      addDimension: function addDimension() {
        return this.addNewDimension();
      },
      destroyDimension: function destroyDimension(item) {
        var items;
        items = this.get('item.items');

        if (item.get('isNew')) {
          items.removeObject(item);
          return item.deleteRecord();
        } else {
          item.deleteRecord();
          item.save().then(function (_this) {
            return function (obj) {};
          }(this), function (obj) {
            item.rollback();
            return alert('Não foi possível excluir esta dimensão.');
          });
          return this.get('model').save();
        }
      }
    }
  });

  _exports.default = _default;
});
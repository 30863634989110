define("api-dashboard/templates/components/waybills/waybill-item-quantities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j+3h4ZdF",
    "block": "{\"symbols\":[\"iq\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"clear-25\"],[8],[9],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fas fa-caret-right\"],[8],[9],[0,\"\\n         Quantidade\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"clear-25\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"item\",\"quantities\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"inputs/input-number\",null,[[\"label\",\"val\",\"min\",\"cols\"],[[23,1,[\"fStore\",\"name\"]],[23,1,[\"quantity\"]],0,2]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"clear-25\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[7,\"strong\",true],[8],[0,\"Quantidade total: \"],[1,[24,[\"item\",\"quantity\"]],false],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/waybills/waybill-item-quantities.hbs"
    }
  });

  _exports.default = _default;
});
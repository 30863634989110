define("api-dashboard/components/checks/check-form", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    cpfCnpjObserver: Ember.observer('model.cnpjCpf', function () {
      var doc;
      doc = this.get('model.cnpjCpf');

      if (!Ember.isEmpty(doc) && !this.validarCNPJ(doc) && !this.validarCPF(doc)) {
        return this.get('application').showAlert('Por favor, corrija o CPF/CNPJ.', 'error');
      }
    }),
    validateClient: function validateClient() {
      var doc;
      doc = this.get('model.cnpjCpf');

      if (!Ember.isEmpty(doc) && !this.validarCNPJ(doc) && !this.validarCPF(doc)) {
        return [false, {
          errorMessages: ['CNPJ inválido!']
        }];
      }

      return [true, {}];
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/waybills/waybill-show", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    selectedLabel: null,
    partialActions: 'components/waybills/waybill-show-actions',
    multipleStores: Ember.computed(function () {
      return this.get('application.currentDomain.visibleStores.length') > 1;
    }),
    multipleLabelModels: Ember.computed(function () {
      var labelModels;
      labelModels = this.get('application.currentDomain.labelModels');
      return labelModels != null && labelModels.get('length') > 1;
    }),
    actions: {
      cancel: function cancel() {
        if (!this.get('model.id')) {
          this.get('store').unloadRecord(this.get('model'));
        }

        return this.sendAction('onCancel', null);
      },
      printWaybill: function printWaybill() {
        var date, filename, self, url;
        self = this;
        this.set('printWaybillSpin', true);
        url = "/waybills/" + this.get('model.id') + "/report";
        date = new Date();
        date = date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear();
        filename = "romaneio_" + this.get('model.supplier.name') + "_" + date + ".pdf";
        return this.downloadURL(url, filename, function () {
          return self.set('printWaybillSpin', false);
        });
      },
      printLabels: function printLabels() {
        var date, ext, filename, label, self, url;
        self = this;

        if (this.get('multipleLabelModels')) {
          if (!this.get('selectedLabel.id')) {
            this.get('application').showAlert('É necessário escolher o Modelo de Etiqueta!', 'error');
            return;
          }
        } else {
          this.set('selectedLabel', this.get('application.currentDomain.labelModels.firstObject'));

          if (!this.get('selectedLabel.id')) {
            this.get('application').showAlert('Não há modelo de etiqueta configurado, contacte o suporte técnico', 'error');
            return;
          }
        }

        this.set('printLabelsSpin', true);
        url = "/labels_report?waybill_id=" + this.get('model.id');

        if (this.get('selectedLabel.id') != null) {
          url += "&label_model_id=" + this.get('selectedLabel.id');
        }

        date = new Date();
        date = date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear();
        label = this.get('selectedLabel.name').replace(/\s/g, '_');
        ext = this.get('selectedLabel.fileExtension');
        filename = "etiquetas_" + this.get('model.supplier.name') + "_" + label + "_" + date + "." + ext;
        return this.downloadURL(url, filename, function () {
          return self.set('printLabelsSpin', false);
        });
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/product-types/product-type-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onSubmit: function onSubmit(product_type) {
        return this.sendAction('afterSave', product_type);
      },
      onCancel: function onCancel(product_type) {
        if (product_type == null) {
          product_type = null;
        }

        return this.sendAction('onCancel', product_type);
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/product-types/product-type-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'product-type',
    title: 'Tipo de Produto',
    titlePlural: 'Tipos de Produto',
    layoutColumns: 6,
    layoutNoRecords: 'product-type/none-registered',
    newRoute: 'product-types/new',
    editRoute: 'product-types/edit',
    partialAfterTitle: null,
    customSearchButton: false,
    refinementsSearch: false,
    showRefinedSearch: false,
    columns: [{
      field: 'name',
      label: 'Nome'
    }, {
      field: 'codigoNcm',
      label: 'Código NCM'
    }],
    searchParams: Ember.computed(function () {
      return [];
    }),
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/components/return-to-suppliers/return-to-supplier-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C+cvvOD+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Novo\"]],\"parameters\":[]},null],[0,\" Devolução a Fornecedor\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"base/base-form\",[],[[\"@model\",\"@onSubmit\",\"@onCancel\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"onSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[5,\"inputs/input-date\",[],[[\"@label\",\"@val\",\"@cols\"],[\"Data de Movimentação\",[24,[\"model\",\"entryDate\"]],\"6\"]]],[0,\"\\n  \"],[5,\"inputs/input-select\",[],[[\"@label\",\"@dataSource\",\"@value\",\"@displayField\",\"@cols\"],[\"Loja de Origem\",[24,[\"globalLookups\",\"stores\"]],[24,[\"model\",\"originStore\"]],\"name\",\"6\"]]],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"Observações\"],[9],[0,\"\\n    \"],[5,\"textarea\",[[12,\"class\",\"form-control\"]],[[\"@rows\",\"@type\",\"@value\"],[\"4\",\"text\",[24,[\"model\",\"comment\"]]]]],[0,\"  \"],[9],[0,\"\\n  \"],[5,\"inputs/product/product-list\",[],[[\"@items\",\"@showTotalCost\",\"@columns\",\"@showTotalValue\",\"@barcodeSelectionVisible\"],[[24,[\"model\",\"items\"]],true,[22,\"productListColumns\"],false,false]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/return-to-suppliers/return-to-supplier-form.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/components/manual-entries/manual-entry-form-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U2mYKpwC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn--default btn--success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"save\"]]],null]]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-save\"],[8],[9],[0,\"\\n  Realizar Movimentação\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/manual-entries/manual-entry-form-actions.hbs"
    }
  });

  _exports.default = _default;
});
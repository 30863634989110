define("api-dashboard/templates/components/waybills/waybill-index-more-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4C8Y7o9S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"apiPanel__button--box\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"canEditItems\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\",\"class\",\"title\"],[[24,[\"editRoute\"]],[24,[\"item\",\"id\"]],\"btn btn--default btn--primary btn--mr\",\"Editar\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-edit\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Editar\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\",\"class\",\"title\"],[[24,[\"editRoute\"]],[24,[\"item\",\"id\"]],\"btn btn--default btn--info btn--mr\",\"Mostrar\"]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Mostrar\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"canEditItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"apiPanel__button--box\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"class\",\"btn btn--default btn--danger\"],[12,\"title\",\"Excluir\"],[3,\"action\",[[23,0,[]],\"delete\",[24,[\"item\"]]]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Excluir\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/waybills/waybill-index-more-actions.hbs"
    }
  });

  _exports.default = _default;
});
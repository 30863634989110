define("api-dashboard/templates/components/sale-types/sale-type-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7QKlIvLv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Novo\"]],\"parameters\":[]},null],[0,\" Tipo de Venda\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"base/base-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],\"onSubmit\",\"onCancel\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"class\"],[\"Nome\",[24,[\"model\",\"name\"]],\"col-xs-12 col-sm-6 col-md-5 col-lg-4\"]]],false],[0,\"\\n    \"],[1,[28,\"inputs/input-select\",null,[[\"label\",\"value\",\"options\",\"class\"],[\"Tipo de Preço\",[24,[\"model\",\"priceType\"]],[24,[\"constants\",\"priceTypes\"]],\"col-xs-12 col-sm-6 col-md-3\"]]],false],[0,\"\\n    \"],[1,[28,\"inputs/input-toggle\",null,[[\"label\",\"value\",\"class\"],[\"Ativo\",[24,[\"model\",\"active\"]],\"col-xs-12 col-sm-6 col-md-3\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/sale-types/sale-type-form.hbs"
    }
  });

  _exports.default = _default;
});
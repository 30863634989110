define("api-dashboard/components/waybills/waybill-new-item", ["exports", "api-dashboard/mixins/formatters", "api-dashboard/mixins/utils"], function (_exports, _formatters, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, _utils.default, {
    application: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    item: null,
    duplicatedItem: false,
    productExists: false,
    itemPriceObserver: Ember.observer('item.cost', function () {
      var price;

      if (this.get('item') && this.get('model.markup') && this.get('item.isNew')) {
        price = this.get('item.cost') * (this.get('model.markup') / 100 + 1);
        return this.set('item.price', price.toFixed(2));
      }
    }),
    _searchProduct: function _searchProduct() {
      var searchParams, self;
      self = this;
      this.set('searchingProduct', true);
      searchParams = {
        code: this.get('item.code'),
        supplier_id: this.get('item.waybill.supplier.id'),
        active: true
      };

      if (!this.get('item.waybill.supplier.id')) {
        searchParams['supplier_id'] = this.get('item.supplier.id');
      }

      return this.get('store').queryRecord('product', searchParams).then(function (obj) {
        var avgCost, markup;

        if (obj) {
          self.set('productExists', true);
          avgCost = new Big(obj.get('averageCost') || 0);
          markup = new Big(self.get('model.markup')).div(100);
          self.get('application').showAlert('Produto encontrado! Os campos foram preenchidos com as' + ' informações do Produto.', 'success');
          self.set('item.product', obj);
          self.set('item.code', obj.get('code'));
          self.set('item.barcode', obj.get('barcode'));
          self.set('item.description', obj.get('description'));
          self.set('item.codigoNcm', obj.get('codigoNcm'));
          self.set('item.supplier', obj.get('supplier'));
          self.set('item.department', obj.get('department'));
          self.set('item.collection', obj.get('collection'));
          self.set('item.productType', obj.get('productType'));
          self.set('item.cost', avgCost);
          self.set('item.price', obj.get('price'));
          self.set('item.waybill', self.get('model'));
          self.set('item.useDimensions', obj.get('useDimensions') || false);

          if (obj.get('useDimensions')) {
            self.__populateItemDimensions(obj);
          }
        }

        return self.set('searchingProduct', false);
      });
    },
    __populateItemDimensions: function __populateItemDimensions(product) {
      var i, itemQty, j, k, l, m, ref, ref1, results, self, store, subItem, subProduct;
      self = this;
      j = 0;

      while (product.get("dimension" + ++j + ".id")) {
        self.set("item.dimension" + j, product.get("dimension" + j));
      }

      results = [];

      for (i = l = 0, ref = product.get('products.length'); 0 <= ref ? l < ref : l > ref; i = 0 <= ref ? ++l : --l) {
        subProduct = product.get('products').objectAt(i);
        subItem = this.get('store').createRecord('waybill-item');
        Ember.set(subItem, 'code', subProduct.get('code'));
        Ember.set(subItem, 'barcode', subProduct.get('barcode'));
        Ember.set(subItem, 'description', subProduct.get('description'));

        if (product.get('useDimensionsPrice')) {
          Ember.set(subItem, 'cost', subProduct.get('cost'));
        } else {
          Ember.set(subItem, 'cost', product.get('cost'));
        }

        Ember.set(subItem, 'price', this.get('item.price'));
        Ember.set(subItem, 'codigoNcm', subProduct.get('codigoNcm'));
        Ember.set(subItem, 'product', subProduct);
        Ember.set(subItem, 'supplier', subProduct.get('supplier'));
        Ember.set(subItem, 'department', subProduct.get('department'));
        Ember.set(subItem, 'productType', subProduct.get('productType'));
        Ember.set(subItem, 'collection', subProduct.get('collection'));
        Ember.set(subItem, 'mainWaybillItem', self.get('item'));
        j = 0;

        while (product.get("dimension" + ++j + ".id")) {
          subItem.set("dimension" + j, product.get("dimension" + j));
          subItem.set("dimension" + j + "Value", subProduct.get("dimension" + j + "Value"));
        }

        for (k = m = 0, ref1 = self.get('application.currentDomain.visibleStores.length'); 0 <= ref1 ? m < ref1 : m > ref1; k = 0 <= ref1 ? ++m : --m) {
          store = self.get('application.currentDomain.visibleStores').objectAt(k);
          itemQty = self.get('store').createRecord('waybill-item-quantity', {
            quantity: 0,
            waybillItem: self.get('item'),
            fStore: store
          });
          subItem.get('quantities').addObject(itemQty);
        }

        results.push(self.get('item.items').addObject(subItem));
      }

      return results;
    },
    checkDimensionValueRepeated: function checkDimensionValueRepeated(propertyNames) {
      var duplicates;
      duplicates = this.findDuplicatesInArray(propertyNames, this.get('item.items'));

      if (!!duplicates && duplicates.length > 0) {
        return this.get('application').showAlert('Existem variantes repetidas, por favor verifique!', 'error', 10000);
      }
    },
    actions: {
      saveFormItem: function saveFormItem() {
        var err, errors, hasDupeItemsDimensions, i, item, l, ref, self;
        hasDupeItemsDimensions = this.checkDimensionValueRepeated(['dimension1Value.valueLabel', 'dimension2Value.valueLabel', 'dimension3Value.valueLabel']);

        if (hasDupeItemsDimensions) {
          this.get('application').showAlert('Por favor, corrija os Itens duplicados antes de prosseguir', 'error', 9000);
          return false;
        }

        item = this.get('item');
        item.validate();
        errors = item.get('errors');

        if (errors.get('length') > 0) {
          for (i = l = 0, ref = errors.get('content.length'); 0 <= ref ? l < ref : l > ref; i = 0 <= ref ? ++l : --l) {
            err = errors.get('content').objectAt(i);
            this.get('application').showAlert("Erro no campo " + err.attribute + ": " + err.message, 'error');
          }

          return;
        }

        self = this;
        item.get('items').forEach(function (i) {
          i.set('price', item.get('price'));
          return i.set('cost', item.get('cost'));
        });
        item.set('waybill', this.get('model'));

        if (this.get('model.supplier.id')) {
          item.set('supplier', this.get('model.supplier'));
        }

        return item.save().then(function (i) {
          var msg;
          self.get('model.items').addObject(item);
          self.get('model').save().then(function () {
            self.set('endItemInsertionEnabled', true);
            return self.addNewWaybillItem();
          });
          self.set('productExists', false);
          item.set('isEditing', false);
          msg = 'Produto adicionado com sucesso!';
          return self.get('application').showAlert(msg, 'success');
        }, function () {
          var msg;
          msg = 'Ocorreu um erro ao salvar o seu item. Seus dados *não* foram salvos. Por favor ' + 'recarregue a página e tente novamente.';
          return self.get('application').showAlert(msg, 'error');
        });
      },
      cancelFormItem: function cancelFormItem() {
        var updatedItems;

        if (confirm('Tem certeza que deseja descartar os dados digitados?')) {
          this.set('productExists', false);
          this.set('item.isEditing', false);
          this.set('item', null);
          this.set('showItemEditForm', false);
          updatedItems = this.get('model.items').filter(function (i) {
            return !i.get('zeroQuantity');
          });
          return this.set('model.items', updatedItems);
        }
      },
      onItemCodeFocusOut: function onItemCodeFocusOut() {
        var duplicatedItem, self;
        self = this;
        this.set('duplicatedItem', false);

        if (Ember.isEmpty(this.get('item.code'))) {
          return;
        }

        duplicatedItem = this.get('model.items').find(function (i) {
          return i.code !== null && i.code !== void 0 && i.code.toLowerCase() === self.get('item.code').toLowerCase() && i.get('supplier.id') === self.get('item.supplier.id');
        });

        if (duplicatedItem !== this.get('item')) {
          this.set('duplicatedItem', true);
          this.set('item.code', '');
          return this.get('application').showAlert('Este produto já foi informado anteriormente.', 'error');
        } else {
          this.set('duplicatedItem', false);
          return this._searchProduct();
        }
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/waybills/waybill-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    model: null,
    searchModelName: 'waybill',
    title: 'Entrada de Produtos',
    titlePlural: 'Entradas de Produtos',
    layoutNoRecords: 'users/none-registered',
    newRoute: 'waybills/new',
    editRoute: 'waybills/edit',
    partialStats: null,
    showNoRecordsLayout: false,
    hideDefaultActionButtons: true,
    partialActions: 'components/waybills/waybill-index-more-actions',
    columns: [{
      label: 'Data',
      field: 'entryDate',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY'
      }
    }, {
      label: 'Fornecedor',
      field: 'supplier.name'
    }, {
      label: 'Loja',
      field: 'fStore.name'
    }, {
      label: 'Número do Documento',
      field: 'documentNumber'
    }, {
      label: 'Situação',
      field: 'statusDescription'
    }, {
      label: 'Custo Total',
      field: 'totalCost',
      format: 'currency'
    }, {
      label: '$ Venda Total',
      field: 'totalValue',
      format: 'currency'
    }, {
      label: 'Qtd. Itens',
      field: 'itemCount'
    }],
    metaLabels: [{
      label: 'Entrada de Produtos: ',
      field: 'total',
      type: ''
    }, {
      label: 'Custo Total: ',
      field: 'total_cost',
      type: 'currency'
    }, {
      label: 'Venda Total: ',
      field: 'total_price',
      type: 'currency'
    }],
    searchParams: Ember.computed(function () {
      var self;
      self = this;
      return [{
        label: 'Situação',
        field: 'status',
        param: 'status',
        component: 'inputs/input-select',
        dataSource: self.get('constants.waybillStatusValuesForSearch'),
        displayField: 'description',
        valueField: 'value.value',
        value: null,
        cols: 6
      }, {
        label: 'Fornecedor',
        field: 'supplier',
        param: 'supplier_id',
        component: 'inputs/input-autocomplete',
        modelName: 'supplier',
        suggestionField: 'name',
        selectedRecord: null,
        value: null,
        cols: 6,
        clearFix: true
      }, {
        label: 'Data Início',
        field: 'entryDate',
        param: 'date_from',
        component: 'inputs/input-date',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD',
          value: null,
          cols: 6
        }
      }, {
        label: 'Data Fim',
        field: 'entryDate',
        param: 'date_to',
        component: 'inputs/input-date',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD',
          value: null,
          cols: 6
        }
      }, {
        label: 'Loja de Origem',
        field: 'fStore',
        param: 'store_id',
        component: 'inputs/input-select',
        dataSource: self.get('application.currentDomain.visibleStores'),
        displayField: 'name',
        valueField: 'id',
        value: null,
        visible: self.get('application.currentDomain.multipleStores'),
        cols: 6
      }, {
        label: 'Número do Documento',
        field: 'documentNumber',
        param: 'document_number',
        component: 'inputs/base-text-field',
        cols: 6
      }];
    }),
    init: function init(args) {
      return this._super(args);
    },
    afterDelete: function afterDelete() {
      return window.location.reload();
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/promotions/promotion-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'promotion',
    title: 'Promoção',
    titlePlural: 'Promoções',
    newRoute: 'promotions/new',
    editRoute: 'promotions/edit',
    partialStats: null,
    allowDelete: false,
    columns: [{
      field: 'name',
      label: 'Nome'
    }],
    searchParams: Ember.computed(function () {
      return [{
        component: 'inputs/input-toggle',
        field: 'search_inactive',
        label: 'Mostrar Inativos',
        param: 'show_inactive',
        value: false
      }];
    }),
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
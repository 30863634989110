define("api-dashboard/templates/components/waybills/waybill-show-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t4bC1hHw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"waybill__show-actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"multipleLabelModels\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"inputs/input-select\",null,[[\"label\",\"dataSource\",\"displayField\",\"value\"],[\"Modelo de etiqueta\",[24,[\"application\",\"currentDomain\",\"labelModels\"]],\"name\",[24,[\"selectedLabel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[28,\"base/spin-button\",null,[[\"buttonText\",\"icon\",\"action\",\"spin\",\"className\"],[\"Imprimir Etiquetas\",\"fas fa-tags\",[24,[\"printLabels\"]],[24,[\"printLabelsSpin\"]],\"btn btn--default btn--primary\"]]],false],[0,\"\\n      \"],[1,[28,\"base/spin-button\",null,[[\"buttonText\",\"icon\",\"action\",\"spin\",\"className\"],[\"Imprimir Romaneio\",\"fas fa-print\",[24,[\"printWaybill\"]],[24,[\"printWaybillSpin\"]],\"btn btn--default btn--primary\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/waybills/waybill-show-actions.hbs"
    }
  });

  _exports.default = _default;
});
define("api-dashboard/templates/components/retorno-boletos/index-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiq+lfGh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"apiPanel__button--box\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--info\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"downloadTxt\",[24,[\"item\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"get\",[[24,[\"downloadingTxt\"]],[28,\"concat\",[[24,[\"item\",\"id\"]],\"\"],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-spin\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fas fa-file-download\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    Baixar Aquivo\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/retorno-boletos/index-actions.hbs"
    }
  });

  _exports.default = _default;
});
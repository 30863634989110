define("api-dashboard/routes/departments/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    model: function model() {
      return this.get('store').createRecord('department');
    },
    actions: {
      afterSave: function afterSave(department) {
        return this.transitionTo('departments/index');
      },
      onCancel: function onCancel(department) {
        if (department == null) {
          department = null;
        }

        return this.transitionTo('departments/index');
      }
    }
  });

  _exports.default = _default;
});
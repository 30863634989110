define("api-dashboard/components/gift-coupons/gift-coupon-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'gift-coupon',
    title: 'Vale-Presente',
    titlePlural: 'Vales-Presente',
    newRoute: 'gift-coupons/new',
    editRoute: 'gift-coupons/edit',
    partialStats: null,
    onlyShowItem: true,
    allowDelete: false,
    columns: [{
      field: 'customer.name',
      label: 'Cliente'
    }, {
      field: 'codeFormatted',
      label: 'Código'
    }, {
      field: 'validUntil',
      label: 'Válido Até',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY'
      }
    }, {
      field: 'statusFormatted',
      label: 'Status'
    }],
    searchParams: [{
      label: 'Código',
      field: 'code',
      param: 'code',
      component: 'inputs/base-text-field',
      value: ''
    }, {
      label: 'Cliente',
      field: 'id',
      param: 'customer_id',
      component: 'inputs/input-autocomplete',
      modelName: 'customer',
      suggestionField: 'name',
      selectedRecord: null,
      value: null,
      clearFix: true
    }],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/card-company-configs/card-company-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    store: Ember.inject.service(),
    cardCompanyConfig: null,
    cardCompanyBrand: null,
    enabledCardBrands: null,
    checked: Ember.computed('enabledCardBrands', function () {
      return this.get('enabledCardBrands') != null && this.get('enabledCardBrands').length > 0;
    })
  }, _defineProperty(_EmberComponent$exte, "enabledCardBrands", Ember.computed('cardCompanyConfig', 'cardCompanyBrand', function () {
    var cardBrandId, cardCompanyConfig, self;
    self = this;
    cardCompanyConfig = this.get('cardCompanyConfig');
    cardBrandId = this.get('cardCompanyBrand.cardBrand.id');

    if (!(cardCompanyConfig != null && cardBrandId != null)) {
      return false;
    }

    return this.get('cardCompanyConfig.enabledCardBrands').then(function (objs) {
      var filteredObjs;
      filteredObjs = objs.filter(function (e) {
        return e.get('cardBrand.id') === cardBrandId;
      });
      self.set('checked', filteredObjs != null && filteredObjs.length > 0);
      return filteredObjs;
    });
  })), _defineProperty(_EmberComponent$exte, "stores", Ember.computed(function () {
    return this.get('store').findAll('f-store');
  })), _EmberComponent$exte));

  _exports.default = _default;
});
define("api-dashboard/components/transfers/transfer-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    globalLookups: Ember.inject.service(),
    constants: Ember.inject.service(),
    model: null,
    searchModelName: 'transfer',
    title: 'Transferência entre Lojas',
    titlePlural: 'Transferências entre Lojas',
    newRoute: 'transfers/new',
    editRoute: 'transfers/edit',
    partialStats: null,
    showFilters: true,
    showRefinedSearch: true,
    allowDelete: false,
    onlyShowItem: true,
    showNoRecordsLayout: false,
    columns: [{
      field: 'trade.entryDate',
      label: 'Data',
      format: 'date',
      options: {
        format: 'DD/MM/YYYY'
      }
    }, {
      field: 'trade.originStore.name',
      label: 'Loja de Origem'
    }, {
      field: 'trade.destinationStore.name',
      label: 'Loja de Destino'
    }, {
      field: 'statusFormatted',
      label: 'Situação'
    }, {
      field: 'number',
      label: 'Número'
    }, {
      field: 'trade.comment',
      label: 'Observação'
    }],
    searchParams: Ember.computed(function () {
      return [{
        label: 'Período De',
        field: 'createdAt',
        param: 'date_from',
        component: 'inputs/input-date',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        }
      }, {
        label: 'Período Até',
        field: 'createdAt',
        param: 'date_to',
        component: 'inputs/input-date',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        }
      }, {
        label: 'Situação',
        field: 'status',
        param: 'status',
        component: 'inputs/input-select',
        displayField: 'description',
        valueField: 'value',
        options: this.get('constants.transferStatuses'),
        value: null
      }, {
        label: 'Loja de Origem',
        field: 'id',
        param: 'origin_store_id',
        component: 'inputs/input-select',
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        prompt: 'Todas',
        value: null
      }, {
        label: 'Loja de Destino',
        field: 'id',
        param: 'destination_store_id',
        component: 'inputs/input-select',
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        prompt: 'Todas',
        value: null
      }];
    }),
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});
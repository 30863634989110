define("api-dashboard/routes/collections/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    model: function model() {
      return this.get('store').createRecord('collection');
    },
    actions: {
      afterSave: function afterSave(collection) {
        return this.transitionTo('collections/index');
      },
      onCancel: function onCancel(collection) {
        if (collection == null) {
          collection = null;
        }

        return this.transitionTo('collections/index');
      }
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/card-company-configs/enabled-card-brand-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this.setInitials();
      return this._super();
    },
    setInitials: function setInitials() {
      var self;
      self = this;

      if (this.get('enabledCardBrands').then) {
        return this.get('enabledCardBrands').then(function (objs) {
          var ecb;
          ecb = objs.find(function (o) {
            return o.get('cardBrand.id') === self.get('cardBrand.id') && o.get('fStore.id') === self.get('fStore.id');
          });
          self.set('enabledCardBrand', ecb);
          return self.set('checked', ecb != null);
        });
      }
    },
    checkedObserver: Ember.observer('checked', function () {
      var enabledCardBrand;
      enabledCardBrand = this.get('enabledCardBrand');

      if (arguments.length > 1) {
        if (this.get('checked')) {
          if (enabledCardBrand != null) {
            enabledCardBrand.set('_destroy', false);
            return this.get('cardCompanyConfig.enabledCardBrands').pushObject(enabledCardBrand);
          } else {
            enabledCardBrand = this.get('store').createRecord('enabledCardBrand');
            enabledCardBrand.set('fStore', this.get('fStore'));
            enabledCardBrand.set('cardBrand', this.get('cardBrand'));
            enabledCardBrand.set('cardCompanyConfig', this.get('cardCompanyConfig'));
            return this.get('cardCompanyConfig.enabledCardBrands').pushObject(enabledCardBrand);
          }
        } else {
          if (enabledCardBrand != null) {
            enabledCardBrand.set('_destroy', true);
            return this.get('cardCompanyConfig.enabledCardBrands').pushObject(enabledCardBrand);
          }
        }
      }
    })
  });

  _exports.default = _default;
});
define("api-dashboard/routes/kits/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    model: function model() {
      return this.get('store').createRecord('kit');
    },
    actions: {
      afterSave: function afterSave(record) {
        return this.transitionTo('kits/edit', record.get('id'));
      },
      onCancel: function onCancel(record) {
        return this.transitionTo('kits/index');
      }
    }
  });

  _exports.default = _default;
});
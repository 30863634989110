define("api-dashboard/routes/manual-input-output/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    router: Ember.inject.service(),
    isUserAuthorized: function isUserAuthorized(user) {
      return this.application.get('currentUser.permStock');
    },
    model: function model() {
      var model;
      model = this.get('store').createRecord('trade');
      model.set('entryDate', new Date());
      return model;
    },
    actions: {
      afterSave: function afterSave(record) {
        if (!record.get('isNew')) {
          alert('Movimentação realizada com sucesso');
        } else {
          alert('Ocorreu um erro na movimentação, por favor contate o suporte técnico informando o produto');
        }

        return window.location.href = this.get('router').urlFor("manual-input-output/new");
      },
      onCancel: function onCancel(record) {
        return this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});
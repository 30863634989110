define("api-dashboard/components/products/product-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    suppliers: Ember.computed('application.currentDomain.suppliers', function () {
      return this.get('application.currentDomain.suppliers');
    }),
    collections: Ember.computed('application.currentDomain.collections', function () {
      return this.get('application.currentDomain.collections');
    }),
    departments: Ember.computed('application.currentDomain.departments', function () {
      return this.get('application.currentDomain.departments');
    }),
    productTypes: Ember.computed('application.currentDomain.productTypes', function () {
      return this.get('application.currentDomain.productTypes');
    }),
    dimensions1: Ember.computed(function () {
      if (this.get('application.currentDomain.dimensions')) {
        return this.get('application.currentDomain.dimensions');
      }
    }),
    dimensionsValues1: Ember.computed('model.dimension1', function () {
      if (this.get('model.dimension1')) {
        return this.get('model.dimension1.values');
      }
    }),
    enableSearchDimensions1: Ember.computed('dimensionsValues1', function () {
      return this.get('dimensionsValues1.length') > 25;
    }),
    dimensions2: Ember.computed('model.dimension1', 'application.currentDomain.dimensions', function () {
      var self;
      self = this;

      if (this.get('application.currentDomain.dimensions') && this.get('model.dimension1.id')) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          return resolve(self.get('application.currentDomain.dimensions').filter(function (item) {
            return item.id !== self.get('model.dimension1.id');
          }));
        });
      }
    }),
    dimensionsValues2: Ember.computed('model.dimension2', function () {
      if (this.get('model.dimension2')) {
        return this.get('model.dimension2.values');
      }
    }),
    enableSearchDimensions2: Ember.computed('dimensionsValues2', function () {
      return this.get('dimensionsValues2.length') > 25;
    }),
    dimensions3: Ember.computed('model.dimension1', 'model.dimension2', 'application.currentDomain.dimensions', function () {
      var self;
      self = this;

      if (this.get('application.currentDomain.dimensions') && this.get('model.dimension1.id') && this.get('model.dimension2.id')) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          return resolve(self.get('application.currentDomain.dimensions').filter(function (item) {
            return item.id !== self.get('model.dimension1.id') && item.id !== self.get('model.dimension2.id');
          }));
        });
      }
    }),
    dimensionsValues3: Ember.computed('model.dimension3', function () {
      if (this.get('model.dimension3')) {
        return this.get('model.dimension3.values');
      }
    }),
    enableSearchDimensions3: Ember.computed('dimensionsValues3', function () {
      return this.get('dimensionsValues3.length') > 25;
    }),
    showDimensionsTab: Ember.computed('model.useDimensions', 'model.isNew', function () {
      return this.get('model.isNew') || this.get('model.useDimensions');
    }),
    sortedValoresParametrosCriteria: ['parametro.descricao:asc'],
    sortedValoresParametros: Ember.computed.sort('model.valoresParametros', 'sortedValoresParametrosCriteria'),
    currentDomain: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain');
    }),
    shouldShowFiscalConfigs: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain.fiscalEnabled');
    }),
    optanteSimples: Ember.computed('application.currentDomain', function () {
      if (this.get('application.currentDomain')) {
        return this.get('application.currentDomain.visibleStores').isEvery('optanteSimples', true);
      }
    }),
    isNfeAndLojas: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain.isNfe') && this.get('application.currentDomain.isLojas');
    }),
    gtinEfetivo: Ember.computed('model.gtin', 'model.barcode', function () {
      if (this.get('model.gtin')) {
        if (this.get('globalLookups').isValidBarcode(this.get('model.gtin'))) {
          return this.get('model.gtin');
        } else {
          return this.get('model.gtin') + " - GTIN inválido!";
        }
      } else if (this.get('globalLookups').isValidBarcode(this.get('model.barcode'))) {
        return this.get('model.barcode') + " - código de barras já é um GTIN válido";
      } else {
        return "Sem GTIN informado";
      }
    }),
    addVariant: function addVariant() {
      return this.get('model.addNewVariant').call(this.get('model'));
    },
    removeVariant: function removeVariant(model) {
      if (!this.get('camposDisabled') && window.confirm('Deseja realmente excluir este item?')) {
        return model.destroyRecord();
      }
    },
    onSubmit: function onSubmit(product) {
      return this.sendAction('afterSave', product);
    },
    actions: {
      onCancel: function onCancel(product) {
        if (product == null) {
          product = null;
        }

        return this.sendAction('onCancel', product);
      },
      activate: function activate() {
        this.set('model.active', true);
        return this.send('save');
      },
      deactivate: function deactivate() {
        var msg;
        msg = 'Tem certeza que deseja desativar este produto?';

        if (this.get('application.currentDomain.isErp')) {
          msg += ' Toda a quantidade em estoque será removida.';
        }

        if (confirm(msg)) {
          this.set('model.active', false);
          return this.send('save');
        }
      }
    }
  });

  _exports.default = _default;
});
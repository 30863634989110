define("api-dashboard/serializers/transfer", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attr_map_serialize_belongsTo: {
      'trade': 'trade_attributes'
    },
    attrs: {
      trade: {
        embedded: 'always'
      }
    },
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      if (relationship === "belongsTo" && method === "serialize" && Ember.isPresent(this.get('attr_map_serialize_belongsTo')[key])) {
        return this.get('attr_map_serialize_belongsTo')[key];
      }

      return this._super(key, relationship, method);
    }
  });

  _exports.default = _default;
});
define("api-dashboard/components/retorno-boletos/retorno-boleto-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/mixins/utils"], function (_exports, _omnisearchIndex, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_utils.default, {
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    allowDelete: false,
    editRoute: 'retorno-boletos/edit',
    model: null,
    newRoute: 'retorno-boletos/new',
    onlyShowItem: true,
    partialStats: null,
    partialActions: 'components/retorno-boletos/index-actions',
    searchModelName: 'retorno-boleto',
    showFilters: true,
    showRefinedSearch: true,
    title: 'Arquivo de Boleto',
    titlePlural: 'Arquivos de Retorno',
    columns: [{
      field: 'contaBancaria.completeDescription',
      label: 'Conta Bancária'
    }, {
      field: 'status',
      label: 'Status'
    }, {
      field: 'createdAt',
      label: 'Enviado Em',
      format: 'date'
    }],
    searchParams: Ember.computed(function () {
      var self;
      self = this;
      return [{
        label: 'Conta Bancária',
        field: 'contaBancaria',
        param: 'conta_bancaria',
        component: 'inputs/input-select',
        displayField: 'completeDescription',
        valueField: 'id',
        dataSource: self.get('globalLookups.bankAccounts'),
        value: null
      }, {
        component: 'inputs/input-date',
        field: 'createdAt',
        label: 'Período De',
        param: 'periodo_de'
      }, {
        component: 'inputs/input-date',
        field: 'createdAt',
        label: 'Período até',
        param: 'periodo_ate'
      }];
    }),
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    },
    actions: {
      downloadTxt: function downloadTxt(obj) {
        var downloadingTxt, objId, reportName, self, url;
        self = this;
        objId = Ember.get(obj, 'id');
        downloadingTxt = [];
        Ember.set(downloadingTxt, String(objId), true);
        this.set('downloadingTxt', downloadingTxt);
        url = "/retornos_boletos/" + objId + ".txt";
        reportName = objId + ".txt";
        return this.downloadURL(url, reportName, function (_this) {
          return function () {
            Ember.set(downloadingTxt, String(objId), false);
            return self.set('downloadingTxt', downloadingTxt);
          };
        }(this));
      }
    }
  });

  _exports.default = _default;
});